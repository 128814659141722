import React from "react";
import downloadIconImage from "../../assets/images/png/download-icon.png";
import backgroundTheme from "../../assets/images/png/EmergingTheme.png";
import ContainerSpinner from "./ContainerSpinner";
function EmergingThemes({
  featureData,
  loading,
  handleEmergingThemes,
  emergingThemes,
  firstRender,
  keyThemesTitle,
  imageId,
  showImageForMoment,
  imageFirstRender,
}) {
  return (
    <div className="relative">
      {(featureData?.key_themes ? loading?.key_themes : loading?.themes) && (
        <div className="content-spinner">
          <ContainerSpinner />
        </div>
      )}{" "}
      <h4 className="py-3 px-5 text-[20px] font-medium text-[#060606]">
        Emerging themes
      </h4>
      <div
        className="mx-5 mb-3"
        style={{
          borderRadius: "10px",
          border: "1px solid rgba(113, 48, 165, 0.3)",
          background: `url(${backgroundTheme}) center/cover no-repeat`,
        }}
      >
        <div className="px-4 pt-4 pb-[20px] md:pb-[55px]">
          <div className="flex justify-between items-center">
            <ul className="flex pt-2 overflow-hidden scroll-none">
              {featureData &&
                (
                  featureData?.key_themes?.key_themes ||
                  featureData?.themes?.themes
                )?.map((res, index) => (
                  <li
                    className={`font-medium whitespace-nowrap text-[#060606]  opacity-70 mr-7 text-[12px] cursor-pointer lg:text-[16px] pb-1 ${
                      firstRender && index === 0
                        ? "border-b-[2px] border-[#674CB4]  opacity-100"
                        : emergingThemes === index
                        ? "border-b-[2px] border-[#674CB4]  opacity-100"
                        : ""
                    }`}
                    key={index}
                    onClick={() => handleEmergingThemes(index, res?.title)}
                  >
                    {res?.title}
                  </li>
                ))}
            </ul>
            <img src={downloadIconImage} className="lg:pr-[10px]" />
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 mt-[20px] md:mt-[55px]">
            <div>
              {firstRender
                ? (
                    featureData?.key_themes?.key_themes ||
                    featureData?.themes?.themes
                  )?.length > 0 && (
                    <img
                      className="mx-auto max-h-[286px] h-full rounded-lg"
                      src={
                        featureData?.key_themes
                          ? featureData.key_themes.key_themes[0]?.public_url
                          : imageFirstRender
                          ? imageId?.public_url
                          : showImageForMoment
                      }
                    />
                  )
                : featureData &&
                  (
                    featureData?.key_themes?.key_themes ||
                    featureData?.themes?.themes
                  )?.map((res) =>
                    res?.title === keyThemesTitle ? (
                      <img
                        className="mx-auto max-h-[286px] h-full rounded-lg"
                        src={
                          featureData?.key_themes
                            ? res?.public_url
                            : imageFirstRender
                            ? imageId?.public_url
                            : showImageForMoment
                        }
                      />
                    ) : null
                  )}
            </div>
            <div className="md:pl-[30px] xl:pl-[10px] xl:pr-[50px] mt-[30px] lg:mt-[0]">
              {featureData &&
                (
                  featureData?.key_themes?.key_themes ||
                  featureData?.themes?.themes
                )?.map((res, index) =>
                  res?.title === keyThemesTitle ? (
                    <div key={index}>
                      <h5 className="text-[28px] font-medium text-[#060606]">
                        {res.title}
                      </h5>
                      <p className="text-[16px] text-[#060606] font-normal mt-3">
                        {res.description}
                      </p>
                    </div>
                  ) : null
                )}
              {firstRender &&
                (
                  featureData?.key_themes?.key_themes ||
                  featureData?.themes?.themes
                )?.length > 0 && (
                  <div>
                    <h5 className="text-[28px] font-medium text-[#060606]">
                      {featureData.key_themes
                        ? featureData.key_themes.key_themes[0].title
                        : featureData?.themes?.themes[0].title}
                    </h5>
                    <p className="text-[16px] text-[#060606] font-normal mt-3">
                      {featureData.key_themes
                        ? featureData.key_themes.key_themes[0].description
                        : featureData?.themes?.themes[0].description}
                    </p>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmergingThemes;
