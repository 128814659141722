import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import imageIcon from "../../assets/images/png/image-icon.png";
import excelIcon from "../../assets/images/png/excel-icon.png";
import { request } from "../../utils/http";

const ImageUploader = ({
  image,
  handleSetUploadedData,
  imageSetInputId,
  handleProgress,
  setIsImageUploadComplete,
  handleSetTotalUploadedFiles,
}) => {
  const [unsupportedFilesError, setUnsupportedFilesError] = useState("");

  const postImageData = async (url, data, filePropName) => {
    const formData = new FormData();
    data.forEach((file) => {
      formData.append(filePropName, file);
    });

    const response = await request({
      method: "POST",
      url: url,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (response) {
      handleSetUploadedData(data);
      handleProgress(1);
    }
  };
  const onDrop = useCallback(
    (acceptedFiles) => {
      handleSetTotalUploadedFiles(acceptedFiles);
      let imageUrl = "";
      let zipUrl = "";
      let CSV_XLSXurl = "";
      const unsupportedFiles = acceptedFiles.filter((file) => {
        if (image) {
          return (
            file.type !== "image/jpeg" &&
            file.type !== "image/png" &&
            file.type !== "application/zip"
          );
        } else {
          return (
            file.type !== "text/csv" &&
            file.type !==
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
        }
      });
      unsupportedFiles && setUnsupportedFilesError(unsupportedFiles);

      if (image) {
        acceptedFiles?.forEach((file) => {
          if (file.type === "image/jpeg" || file.type === "image/png") {
            imageUrl = `/image_sets_inputs/${imageSetInputId}/image_data/image_files`;
            unsupportedFiles?.length === 0 &&
              postImageData(imageUrl, [file], "image_files");
            setIsImageUploadComplete(false);
          }
          if (file.type === "application/zip") {
            zipUrl = `/image_sets_inputs/${imageSetInputId}/image_data/image_files`;
            unsupportedFiles?.length === 0 &&
              postImageData(zipUrl, [file], "zip_file");
            setIsImageUploadComplete(false);
          }
        });
        handleSetUploadedData([]);
        handleProgress(0);
      } else {
        acceptedFiles?.forEach((file) => {
          if (
            file.type === "text/csv" ||
            file.type ===
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ) {
            CSV_XLSXurl = `/image_sets_inputs/${imageSetInputId}/metadata`;
            unsupportedFiles?.length === 0 &&
              postImageData(CSV_XLSXurl, [file], "csv_xlsx_file");
          }
        });

        handleSetUploadedData([]);
        handleProgress(0);
      }
    },
    [imageSetInputId]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: image === "image" ? true : false,
    accept: "image/jpeg, image/png, application/zip",
  });

  return (
    <div>
      <div
        {...getRootProps()}
        className={`dropzone ${
          isDragActive ? "active" : ""
        } w-full mt-2 p-6 md:p-12 relative border-2 rounded-2xl bg-[#F1EBF6] group flex flex-col items-center justify-center border-dashed   border-[#674CB4E5] cursor-pointer`}
      >
        {image ? (
          <img src={imageIcon} className="h-[36px]" alt="" />
        ) : (
          <img src={excelIcon} alt="" />
        )}
        <input {...getInputProps()} />
        {image ? (
          <p className="text-[14px] md:text-[16px] text-[#060606B2] font-[500] mt-3 text-center">
            Drag and drop image here or{" "}
            <a className="text-[#674CB4] underline">Choose image(s)</a>{" "}
          </p>
        ) : (
          <p className="text-[14px] md:text-[16px] text-[#060606B2] font-[500] mt-3 text-center">
            Drag and drop file here or{" "}
            <a className="text-[#674CB4] underline">Choose file</a>{" "}
          </p>
        )}
      </div>
      {image ? (
        <span
          className={`text-[14px] mt-2 inline-block ${
            unsupportedFilesError && unsupportedFilesError.length > 0
              ? "text-[#ff1c1cb2]"
              : "text-[#060606B2]"
          } font-[400] `}
        >
          Supported format: jpg, png, zip file
        </span>
      ) : (
        <span
          className={`text-[14px] mt-2 inline-block ${
            unsupportedFilesError && unsupportedFilesError.length > 0
              ? "text-[#ff1c1cb2]"
              : "text-[#060606B2]"
          } font-[400] `}
        >
          Supported format: CSV, XLSX
        </span>
      )}
    </div>
  );
};

export default ImageUploader;
