import {GenderSVG,LocationSVG,TimeSVG,GroupSizeSVG} from "../../svg/Icons";

export const sidebarData = [
  {
    id: 1,
    name: "Group Size",
    key: "group_size",
    activeIcon: <GroupSizeSVG strokeColor="#674CB4" />,
    inactiveIcon: <GroupSizeSVG />,
    isActive: true,
    children: [
      { id: 1, name: "0", value: "0", isChecked: false },
      { id: 2, name: "1", value: "1", isChecked: false },
      { id: 3, name: "2", value: "2", isChecked: false },
      { id: 4, name: "3-4", value: "3-4", isChecked: false },
      { id: 5, name: "5+", value: "5+", isChecked: false },
    ],
  },
  {
    id: 2,
    name: "Gender",
    key: "gender",
    activeIcon: <GenderSVG strokeColor="#674CB4" />,
    inactiveIcon: <GenderSVG />,
    isActive: false,
    children: [
      { id: 1, name: "Males only", value: "males only", isChecked: false },
      { id: 2, name: "Females only", value: "females only", isChecked: false },
      {
        id: 3,
        name: "Mixed with more males",
        value: "mixed with more males",
        isChecked: false,
      },
      {
        id: 4,
        name: "Mixed with more females",
        value: "mixed with more females",
        isChecked: false,
      },
      { id: 5, name: "Mixed equal", value: "mixed equal", isChecked: false },
      { id: 6, name: "Others", value: "others", isChecked: false },
    ],
  },
  {
    id: 3,
    name: "Location",
    key: "location",
    activeIcon: <LocationSVG strokeColor="#674CB4" />,
    inactiveIcon: <LocationSVG />,
    isActive: false,
    children: [
      {
        id: 1,
        name: "Indoor",
        key: "indoor",
        isActive: true,
        children: [
          { id: 1, name: "Home", value: "home", isChecked: false },
          { id: 2, name: "Office", value: "office", isChecked: false },
          { id: 3, name: "Restaurant", value: "restaurant", isChecked: false },
          { id: 4, name: "Others", value: "others", isChecked: false },
          { id: 5, name: "N/A", value: "N/A", isChecked: false },
        ],
      },
      { id: 2, name: "Outdoor", key: "outdoor", isActive: true, children: [] },
    ],
  },
  {
    id: 4,
    name: "Time",
    key: "time_of_day",
    activeIcon: <TimeSVG strokeColor="#674CB4" />,
    inactiveIcon: <TimeSVG />,
    isActive: false,
    children: [
      { id: 1, name: "Late morning", value: "late morning", isChecked: false },
      { id: 2, name: "Afternoon", value: "afternoon", isChecked: false },
      {
        id: 3,
        name: "Late afternoon",
        value: "late afternoon",
        isChecked: false,
      },
      {
        id: 4,
        name: "Early evening",
        value: "early evening",
        isChecked: false,
      },
      { id: 5, name: "Late evening", value: "late evening", isChecked: false },
      { id: 6, name: "Others", value: "others", isChecked: false },
    ],
  },
];
