import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const StatsPie = ({ statsData }) => {
  const [series, setSeries] = useState([]);
  const [labels, setLabels] = useState([]);
  const [options, setOptions] = useState({
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              show: true,
            },
            value: {
              show: false,
            },
            total: {
              show: true,
              label: "Gender Ratio",
              color: "#060606",
              fontSize: "17px",
              fontFamily: "Poppins",
              fontWeight: "normal",
            },
          },
        },
      },
    },
    legend: {
      position: "bottom",
      formatter: function (seriesName, opts) {
        return (
          opts.w.globals.labels[opts.seriesIndex] +
          ": " +
          opts.w.globals.series[opts.seriesIndex]
        );
      },
      markers: {
        width: 9,
        height: 9,
      },
      fontSize: "12px",
      fontFamily: "Poppins",
      fontWeight: "normal",
      colors: "#060606",
    },
    colors: ["#FFD639", "#FC7753", "#66D7D1", "#DBD56E", "#403D58"],
    dataLabels: {
      style: {
        fontSize: "12px",
        fontFamily: "Poppins",
        fontWeight: "500",
        colors: ["#060606"],
      },
    },
  });

  useEffect(() => {
    let genderKeys = Object.keys(statsData?.gender);
    let genderValues = Object.values(statsData?.gender);

    setSeries(genderValues);
    setLabels(genderKeys);
    setOptions((prevOptions) => ({
      ...prevOptions,
      labels: genderKeys,
    }));
  }, [statsData]);

  return (
    <div className="donut">
      <Chart options={options} series={series} type="donut" width="380" />
    </div>
  );
};

export default StatsPie;
