import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import ReactApexChart from "react-apexcharts";

const colors = ["#FFD639", "#FC7753","#66D7D1", "#DBD56E", "#403D58"];

const TimeOfDayStats = ({ statsData }) => {
  const [chartState, setChartState] = useState({
    series: [
      {
        data: [],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "bar",
        events: {
          click: function (chart, w, e) {},
        },
      },
      colors: colors,
      plotOptions: {
        bar: {
          columnWidth: "10%",
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: [],
        title: {
          text: "Time of day",
          style: {
            fontSize: "12px",
            fontWeight: "normal",
            fontFamily: "Poppins",
            color: "rgba(6, 6, 6, 0.7)",
          },
        },
        labels: {
          style: {
            fontSize: "12px",
            fontWeight: "normal",
            fontFamily: "Poppins",
            color: "rgba(6, 6, 6, 0.7)",
          },
        },
      },
      yaxis: {
        title: {
          text: "Images",
          style: {
            fontSize: "12px",
            fontWeight: "normal",
            fontFamily: "Poppins",
            color: "rgba(6, 6, 6, 0.7)",
          },
        },
        labels: {
          formatter: function (value) {
            return Math.round(value);
          },
        },
      },

      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const xValue = w.config.xaxis.categories[dataPointIndex];
          const yValue = series[seriesIndex][dataPointIndex];

          const groupSizeTooltip = `<div class="tooltip-item">Time of day: <span>${xValue}</span></div>`;
          const numberOfImagesTooltip = `<div class="tooltip-item">Number of Images: <span>${yValue}</span></div>`;

          return `<div class="custom-tooltip">${groupSizeTooltip}${numberOfImagesTooltip}</div>`;
        },
      },
    },
  });

  useEffect(() => {
    const desiredOrder = [
      "late morning",
      "afternoon",
      "late afternoon",
      "early evening",
      "late evening",
      "others",
    ];

    let sortedCategories = Object.keys(statsData?.time_of_day).sort((a, b) => {
      return desiredOrder.indexOf(a) - desiredOrder.indexOf(b);
    });

    let groupSizeValues = sortedCategories.map(
      (category) => statsData?.time_of_day[category]
    );

    /* let groupSizeKeys = Object.keys(statsData?.time_of_day);

    let groupSizeValues = Object.values(statsData?.time_of_day); */

    setChartState((prevState) => ({
      ...prevState,
      series: [{ data: groupSizeValues }],
      options: {
        ...prevState.options,
        xaxis: {
          ...prevState.options.xaxis,
          categories: sortedCategories,
        },
        chart: {
          ...prevState.options.chart,
          toolbar: {
            show: false, // Set show to false to hide the download menu
          },
        },
      },
    }));
  }, [statsData]);

  return (
    <div id="chart" className="w-full">
      <ReactApexChart
        options={chartState.options}
        series={chartState.series}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default TimeOfDayStats;
