import React from "react";
import ContainerSpinner from "./ContainerSpinner";
import tickIcon from "../../assets/images/png/tick.png";

function BrandRelevance_Marketing({
  featureData,
  loading,
  brandRelevance,
  handleBrandRelevance,
  marketingOpportunities,
  handleMarketingOpportunities,
}) {
  return (
    <div className="relative">
      {(loading?.brand_marketing || loading?.brand_relevance) && (
        <div className="content-spinner">
          <ContainerSpinner />
        </div>
      )}{" "}
      <h4 className="py-3 px-5 text-[20px] font-medium text-[#060606]">
        Brand Insights
      </h4>
      <div className="max-sm:px-2 mx-5 py-4 rounded border-secondaryBlack">
        <div className="max-sm:gap-x-4 flex items-center sm:gap-x-10">
          <button
            className={
              brandRelevance
                ? "font-medium pb-1 border-b-[2px] border-[#674CB4] opacity-100"
                : "font-medium pb-1 border-b-[2px] border-transparent opacity-70"
            }
            onClick={handleBrandRelevance}
          >
            Brand Relevance
          </button>

          <button
            className={
              marketingOpportunities
                ? "font-medium pb-1 border-b-[2px] border-[#674CB4] opacity-100"
                : "font-medium pb-1 border-b-[2px] border-transparent opacity-70"
            }
            onClick={handleMarketingOpportunities}
          >
            Marketing Opportunities
          </button>
        </div>

        {loading?.brand_marketing || loading?.brand_relevance ? (
          <ContainerSpinner />
        ) : (
          <div className="scroll-element max-h-96 overflow-y-auto pr-5">
            {brandRelevance &&
              featureData &&
              featureData?.brand_relevance?.brand_relevance?.map(
                (res, index) => (
                  <div
                    className="max-sm:gap-x-1 my-4 flex items-start sm:gap-x-3"
                    key={index}
                  >
                    <div className="flex items-start">
                      <img src={tickIcon} />
                      <div className="ml-2">
                        <h6 className="text-[#000000] text-[16px] font-medium">
                          {res?.title}
                        </h6>
                        <p className="mt-2">{res?.description}</p>
                      </div>
                    </div>
                  </div>
                )
              )}
            {marketingOpportunities &&
              featureData &&
              featureData?.brand_marketing?.brand_marketing?.map(
                (res, index) => (
                  <div
                    className="max-sm:gap-x-1 my-4 flex items-start sm:gap-x-3"
                    key={index}
                  >
                    <div className="flex items-start">
                      <img src={tickIcon} />
                      <div className="ml-2">
                        <h6 className="text-[#000000] text-[16px] font-medium">
                          {res?.title}
                        </h6>
                        <p className="mt-2">{res?.description}</p>
                      </div>
                    </div>
                  </div>
                )
              )}
          </div>
        )}
      </div>
    </div>
  );
}

export default BrandRelevance_Marketing;
