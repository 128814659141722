import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const colors = ["#DBD56E"];

const LocationStats = ({ statsData }) => {
  const [chartState, setChartState] = useState({
    series: [
      {
        data: [],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "bar",
        events: {
          click: function (chart, w, e) {},
        },
      },
      colors: colors,
      plotOptions: {
        bar: {
          columnWidth: "10%",
          distributed: true,
          horizontal: true,
          barHeight: 10,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: [],
        title: {
          text: "Images",
          style: {
            fontSize: "12px",
            fontWeight: "normal",
            fontFamily: "Poppins",
            color: "rgba(6, 6, 6, 0.7)",
          },
        },
        labels: {
          style: {
            fontSize: "12px",
            fontWeight: "normal",
            fontFamily: "Poppins",
            color: "rgba(6, 6, 6, 0.7)",
          },
        },
      },
      yaxis: {
        title: {
          text: "Locations",
          style: {
            fontSize: "12px",
            fontWeight: "normal",
            fontFamily: "Poppins",
            color: "rgba(6, 6, 6, 0.7)",
          },
        },
        labels: {
          style: {
            fontSize: "12px",
            fontWeight: "normal",
            fontFamily: "Poppins",
            color: "rgba(6, 6, 6, 0.7)",
          },
        },
      },

      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const xValue = w.config.xaxis.categories[dataPointIndex];
          const yValue = series[seriesIndex][dataPointIndex];

          const groupSizeTooltip = `<div class="tooltip-item">Group Size: <span>${xValue}</span></div>`;
          const numberOfImagesTooltip = `<div class="tooltip-item">Number of Images: <span>${yValue}</span></div>`;

          return `<div class="custom-tooltip">${groupSizeTooltip}${numberOfImagesTooltip}</div>`;
        },
      },
    },
  });

  useEffect(() => {
    if (statsData?.indoor?.hasOwnProperty("N/A")) {
      delete statsData?.indoor["N/A"];
    }
    if (statsData?.outdoor?.hasOwnProperty("N/A")) {
      delete statsData?.outdoor["N/A"];
    }

    let indoorKeys = statsData?.indoor ? Object.keys(statsData?.indoor) : [];

    let indoorValues = statsData?.indoor
      ? Object.values(statsData?.indoor)
      : [];

    let outdoorKeys = statsData?.outdoor ? Object.keys(statsData?.outdoor) : [];

    let outdoorValues = statsData?.outdoor
      ? Object.values(statsData?.outdoor)
      : [];

    setChartState((prevState) => ({
      ...prevState,
      series: [{ data: [...outdoorValues, ...indoorValues] }],
      options: {
        ...prevState.options,
        colors: [
          ...indoorValues.map(() => "#DBD56E"),
          ...outdoorValues.map(() => "#FFAEBC"),
        ],
        xaxis: {
          ...prevState.options.xaxis,
          categories: [...outdoorKeys, ...indoorKeys],
        },
        chart: {
          ...prevState.options.chart,
          toolbar: {
            show: false,
          },
        },
      },
    }));
  }, [statsData]);

  return (
    <div id="chart" className="w-full">
      <ReactApexChart
        options={chartState.options}
        series={chartState.series}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default LocationStats;
