import React, { useEffect, useRef } from "react";
import Plotly from "plotly.js-basic-dist";

const BubbleMoment = ({ momentData }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const customColors = [
      "rgba(247, 181, 68, 0.5)",
      "rgba(209, 137, 145, 1)",
      "rgba(109, 190, 189, 1)",
      "rgba(155, 189, 237, 1)",
      "rgba(146, 151, 199, 1)",
      "rgba(235, 176, 205, 1)",
      "rgba(255, 241, 153, 1)",
    ];
    const desiredOrder = [
      "late morning",
      "afternoon",
      "late afternoon",
      "early evening",
      "late evening",
      "others",
    ];
    const trace1 = momentData?.map((res, index) => ({
      x: Object.keys(res?.stats?.time_of_day),
      y: Object.values(res?.stats?.time_of_day),
      mode: "markers",
      marker: {
        size: Object.values(res?.stats?.time_of_day).map((value) => value * 10),
        color: customColors[index % customColors.length],
      },
      name: res?.moment_name,
      text: Array(Object.keys(res?.stats?.time_of_day).length).fill(
        res?.moment_name
      ),
      hoverinfo: "text",
    }));

    const layout = {
      showlegend: true,
      legend: {
        itemsizing: "constant",
        font: {
          family: "Poppins",
          size: 13,
          weight: "normal", // Adjust the font size for y-axis values
          color: "rgba(6, 6, 6, 1)", // Adjust the font color for y-axis values
        },
      },
      xaxis: {
        title: {
          text: "Time of Day",
          font: {
            family: "Poppins",
            size: 12,
            weight: "bold",
            color: "rgba(6, 6, 6, 0.7)", // You can specify the color here
          },
        },
        tickfont: {
          family: "Poppins",
          size: 9,
          weight: "normal", // Adjust the font size for y-axis values
          color: "rgba(6, 6, 6, 1)", // Adjust the font color for y-axis values
        },

        categoryorder: "array",
        categoryarray: desiredOrder,
      },
      yaxis: {
        title: {
          text: "Number of images",
          font: {
            family: "Poppins",
            size: 12,
            weight: "bold",
            color: "rgba(6, 6, 6, 0.7)", // You can specify the color here
          },
        },
        tickfont: {
          family: "Poppins",
          size: 9,
          weight: "normal", // Adjust the font size for y-axis values
          color: "rgba(6, 6, 6, 1)", // Adjust the font color for y-axis values
        },
      },
      /* height: 1000,
      width: 1000, */
    };

    // Ensure the div element exists before using Plotly
    if (chartRef.current) {
      // Use Plotly.react to update the plot or create it if it doesn't exist
      Plotly.react(chartRef.current, trace1, layout);
    }

    // Cleanup when the component unmounts
    return () => {
      if (chartRef.current) {
        Plotly.purge(chartRef.current);
      }
    };
  }, [momentData]);

  return (
    <>
      {momentData && (
        <div id="myDiv" className="chart-container" ref={chartRef}></div>
      )}
    </>
  );
};

export default BubbleMoment;
