import React from "react";
import SlidingSquareLoader from "../SlidingSquareLoader";

const StatusResponse_Modal = ({ StatusResponseModalData }) => {
  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-75 flex">
      <div className="bg-[#F1EBF6] w-full min-h-[200px] max-w-xl my-auto md:mx-auto rounded-lg">
        <div className="my-5 text-center px-4">
          <p className="font-medium text-[16px] text-[#674CB4]">
            HOLD ON TIGHT!
          </p>
          <p className="my-3 font-medium text-[16px] md:text-[18px] text-[#060606]">
            {StatusResponseModalData}
          </p>
          <p className="font-medium text-[16px] text-[#06060699]">
            Crafting personalized insights just for you
          </p>
        </div>
        <SlidingSquareLoader />
      </div>
    </div>
  );
};

export default StatusResponse_Modal;
