import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import cameraIcon from "../assets/images/png/camera.png";
import barIcon from "../assets/images/png/bar.png";
import desiredIcon from "../assets/images/png/hug.png";
import ImageUploader from "./components/ImageUploader";
import { request } from "../utils/http";
import { FileCloseSVG } from "../svg/Icons";

import logo from "../assets/images/png/logo.png";

const UploadImage = () => {
  const navigate = useNavigate();
  const [uploadedData, setUploadedData] = useState([]);
  const [totalUploadedFiles, setTotalUploadedFiles] = useState([]);
  const [imageSetInputId, setImageSetInputId] = useState(null);
  const [isImageUploadComplete, setIsImageUploadComplete] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [progress, setProgress] = useState(0);
  const [modalInputValue, setModalInputValue] = useState("");
  const [modalError, setModalError] = useState("");

  const formatSize = (sizeInBytes) => {
    const kilobytes = sizeInBytes / 1024;
    if (kilobytes < 1024) {
      return kilobytes.toFixed(2) + " KB";
    } else {
      const megabytes = kilobytes / 1024;
      return megabytes.toFixed(2) + " MB";
    }
  };
  const removeImage = (index) => {
    setUploadedData((prevImages) => prevImages.filter((_, i) => i !== index));
  };
  const handleSetUploadedData = (data) => {
    setUploadedData((prev) => [...prev, ...data]);
  };
  const handleSetTotalUploadedFiles = (data) => {
    setTotalUploadedFiles((prev) => [...prev, ...data]);
  };
  const handleProgress = (progressData) => {
    setProgress((prev) => prev + progressData);
  };

  const getImageSetInputId = async () => {
    const response = await request({
      method: "POST",
      url: `/image_sets_inputs/`,
    });

    response && setImageSetInputId(response.data);
  };

  const createNewImageSetRun = async () => {
    try {
      const response = await request({
        method: "POST",
        url: `/image_set_analysis/${modalInputValue}?cache=true`,
      });
      if (response && response.status === 200) navigate("/");
    } catch (error) {
      console.log(error);
    }
  };
  const createNewImageSet = async () => {
    if (!modalInputValue.trim()) {
      setModalError("Project Name is required");
      return;
    }
    let postData = {
      image_set: modalInputValue,
      image_set_inputs_id: imageSetInputId,
    };

    try {
      const response = await request({
        method: "POST",
        url: `/image_sets/`,
        data: postData,
      });
      if (response && response?.status === 200) {
        setModalError("");
        setIsModalVisible(false);
        createNewImageSetRun();
      }
    } catch (error) {
      setModalError(error?.response?.data?.detail);
    }
  };

  useEffect(() => {
    getImageSetInputId();
  }, []);

  return (
    <div className="mx-auto min-h-screen container-xl text-[#060606]">
      <div className="p-3">
        <Link to="/" className="w-[100px] h-12 block">
          <img
            src={logo}
            alt="Logo of the Lens app"
            className="w-full h-full object-contain"
          />
        </Link>
        <div className="card border border=[#ECE5E5] rounded=[8px] p-[30px] mt-3">
          <h3 className="text-[24px] text-[#060606] font-[500] tracking-[0.24px] opacity-80">
            Let’s get you started!
          </h3>
          <span className="text-[14px] mt-2 text-[#060606] font-[400] tracking-[0.14px] opacity-70">
            Begin by uploading images, files to get detailed analytics.
          </span>
          <h4 className="text-[20px] mt-5 text-[#060606] font-[500] tracking-[0.20px]">
            Create a Project
          </h4>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
            <div>
              <label className="text-[16px] font-[400] tracking-[0.16px] text-[#060606]">
                Upload images*
              </label>
              <ImageUploader
                image="image"
                handleSetUploadedData={handleSetUploadedData}
                imageSetInputId={imageSetInputId}
                handleProgress={handleProgress}
                setIsImageUploadComplete={setIsImageUploadComplete}
                handleSetTotalUploadedFiles={handleSetTotalUploadedFiles}
              />
            </div>
            <div>
              <label className="text-[16px] text-[#060606] font-[400] tracking-[0.16px]">
                Upload Metadata
              </label>
              <ImageUploader
                handleSetUploadedData={handleSetUploadedData}
                imageSetInputId={imageSetInputId}
                handleProgress={handleProgress}
                handleSetTotalUploadedFiles={handleSetTotalUploadedFiles}
              />
            </div>
          </div>
          {uploadedData.length > 0 ? (
            <>
              {progress !== totalUploadedFiles.length && (
                <>
                  <h4 className="text-[16px] mt-4 text-[#060606] font-[400] tracking-[0.16px]">
                    {`Uploading - ${progress || ""}/${
                      uploadedData.length
                    } file`}
                  </h4>
                  <div className="gird grid-cols-1 gap-3 mt-2 relative">
                    <div className="border border-[#ECE5E5] px-4 py-2 rounded-[8px] flex justify-between">
                      <div className="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <circle
                            cx="11"
                            cy="11"
                            r="10"
                            stroke="#060606"
                            stroke-opacity="0.3"
                          />
                        </svg>
                        <span className="text-[14px] md:text-[16px] ml-3 text-[#060606] font-[400] tracking-[0.16px] text-ellipsis overflow-hidden max-w-[100px] sm:max-width-[100%] whitespace-nowrap">
                          {uploadedData[uploadedData.length - 1]?.name}
                        </span>
                      </div>
                      <div className="flex items-center">
                        <span className="inline-block rounded-[4px] border border-[#0606064D] px-[10px] py-[4px] text-[14px] text-[#06060680] font-[400] tracking-[0.14px] mr-5">
                          {uploadedData[uploadedData.length - 1]?.size}
                        </span>
                        <FileCloseSVG />
                      </div>
                    </div>
                    <progress
                      max={uploadedData.length}
                      value={progress}
                      className="w-[95%] h-[1.5px] absolute left-[50%] translate-x-[-50%] bottom-[4px]"
                    ></progress>
                  </div>
                </>
              )}
              <h4 className="text-[16px] mt-4 mb-2 text-[#060606] font-[400] tracking-[0.16px]">
                {`Uploaded - ${uploadedData.length} files`}
              </h4>
              <div className="px-1 md:px-3 max-h-[200px] scroll-element overflow-y-auto">
                {uploadedData?.map((image, index) => {
                  return (
                    <>
                      <div className="gird grid-cols-1 gap-3">
                        <div className="border border-[#ECE5E5] px-2 md:px-4 py-2 rounded-[8px] flex justify-between mb-2">
                          <div className="flex items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <circle cx="10" cy="10" r="10" fill="#49AB2E" />
                              <path
                                d="M6.66797 10.0001L8.89019 12.2223L13.3346 7.77783"
                                stroke="white"
                                stroke-linecap="round"
                              />
                            </svg>
                            <span className="text-[14px] md:text-[16px] ml-3 text-[#060606] font-[400] tracking-[0.16px] text-ellipsis overflow-hidden max-w-[100px] sm:max-width-[100%] whitespace-nowrap">
                              {image?.name}
                            </span>
                          </div>
                          <div className="flex items-center">
                            <span className="inline-block rounded-[4px] border border-[#0606064D] px-[5px] md:px-[10px] py-[4px] text-[12px] md:text-[14px] text-[#06060680] font-[400] tracking-[0.14px] mr-2 md:mr-5">
                              {formatSize(image?.size)}
                            </span>
                            <FileCloseSVG />
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>

              <div className="mt-3 text-right">
                <button
                  className={`${
                    progress !== totalUploadedFiles.length
                      ? "bg-[#674CB466]"
                      : "bg-[#674CB4]"
                  }  py-[10px] text-white font-[400] rounded-[8px] px-[33px] mt-3`}
                  onClick={() => setIsModalVisible(!isModalVisible)}
                  disabled={isImageUploadComplete}
                >
                  Next
                </button>
              </div>
            </>
          ) : (
            <>
              <h4 className="text-[20px] mt-5 mb-3 text-[#060606] font-[500] tracking-[0.20px]">
                How this works?
              </h4>
              <div>
                <div className="flex items-start">
                  <img src={cameraIcon} alt="" className="mr-3" />
                  <label className="text-[12px] md:text-[16px] text-[#060606] font-[400] tracking-[0.16px]">
                    Upload image(s) and/or metadata file(s) to create a new
                    project.
                  </label>
                </div>
                <div className="flex items-start my-2">
                  <img src={barIcon} alt="" className="mr-3" />
                  <label className="text-[12px] md:text-[16px] text-[#060606] font-[400] tracking-[0.16px]">
                    Upload image(s) and/or metadata Get detailed statistics and
                    brand related insights on single.
                  </label>
                </div>
                <div className="flex items-start">
                  <img src={desiredIcon} alt="" className="mr-3" />
                  <label className="text-[12px] md:text-[16px] text-[#060606] font-[400] tracking-[0.16px]">
                    Ask further questions about the image(s) to obtain desired
                    analysis.
                  </label>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {isModalVisible && (
        <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-75 flex">
          <div className="relative bg-white w-full max-w-xl my-auto md:mx-auto rounded-lg flex-col flex">
            <div className="flex justify-between items-center bg-[#7130A51A] px-[40px] py-3">
              <h1 className="text-[24px] text-[#060606] font-[500]">
                Set Project Name
              </h1>
              <div
                className="cursor-pointer text-[#000]"
                onClick={() => {
                  setIsModalVisible(false);
                  setModalError("");
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M4 4L16 16M4 16L16 4L4 16Z"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>

            <div className="p-[40px]">
              <label
                htmlFor="modalInput"
                className="text-[16px] text-[#060606] font-[500]"
              >
                Project Name*
              </label>
              <input
                type="text"
                id="modalInput"
                className="w-full border border-[#7979794D] rounded-[10px] p-3 mt-1 text-[16px] text-[#060606] font-[500] outline-none"
                placeholder="Enter Project Name"
                value={modalInputValue}
                onChange={(e) => setModalInputValue(e.target.value)}
                onBlur={() => {
                  if (!modalInputValue.trim()) {
                    setModalError("Project Name is required");
                  } else {
                    setModalError("");
                  }
                }}
              />
              <span className="text-[#ff1c1cb2] text-[12px]">{modalError}</span>
            </div>

            <div className="flex justify-end  pb-[40px]  px-[40px]">
              <button
                onClick={() => {
                  setIsModalVisible(false);
                  setModalInputValue("");
                  setModalError("");
                }}
                className=" text-[#070B32] px-4 py-2"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  createNewImageSet();
                }}
                className="bg-[#674CB4] text-white px-4 py-2 rounded"
                disabled={modalError}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadImage;
