import React from "react";
import ContainerSpinner from "./ContainerSpinner";

function Emotions_Motivations({ featureData, loading }) {
  const customBorderColor = ["#1A932E", "#2B5CE6", "#E65F2B", "#D39730"];
  const customBgColors = ["#1A932E2E", "#2B5CE62E", "#E65F2B2E", "#F0C2742E"];
  return (
    <div className="px-[10px] sm:px-[50px] my-4 flex justify-between flex-col xl:flex-row relative">
      {loading?.emotions_and_motivations && (
        <div className="content-spinner">
          <ContainerSpinner />
        </div>
      )}
      <div className="flex mb-[15px] xl:mb-[0] md:w-[50%] flex-col md-flex-row">
        <h5 className="text-[#060606] mb-3 font-normal text-[16px] ml-3 md:mb-0  md:ml-0">
          Emotions
        </h5>

        <div className="md:ml-2">
          {featureData &&
            featureData?.emotions_and_motivations?.emotions?.map(
              (res, index) => {
                return (
                  <span
                    className={`text-[14px] font-normal py-[3px] ml-3 mb-2 px-[10px] inline-block rounded-full`}
                    key={index}
                    style={{
                      backgroundColor:
                        customBgColors[index % customBgColors.length] || "",
                      color:
                        customBorderColor[index % customBorderColor.length] ||
                        "",
                    }}
                  >
                    {res}
                  </span>
                );
              }
            )}
        </div>
      </div>

      <div className="flex md:w-[50%] flex-col md-flex-row">
        <h5 className="text-[#060606] mb-3 font-normal text-[16px] ml-3 md:mb-0  md:ml-0">
          Motivations
        </h5>
        <div className="md:ml-2">
          {featureData &&
            featureData?.emotions_and_motivations?.motivations?.map(
              (res, index) => {
                return (
                  <span
                    className="text-[14px] font-normal text-[#060606] py-[3px] ml-3 mb-2 px-[10px] inline-block bg-[#c1b9b92e] rounded-full"
                    key={index}
                  >
                    {res}
                  </span>
                );
              }
            )}
        </div>
      </div>
    </div>
  );
}

export default Emotions_Motivations;
