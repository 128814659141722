import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const colors = ["#F0C274"];

const LocationApexChart = ({ momentData, momentId }) => {
  const [chartState, setChartState] = useState({
    series: [
      {
        data: [],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "bar",
        events: {
          click: function (chart, w, e) {},
        },
      },
      colors: colors,
      plotOptions: {
        bar: {
          columnWidth: "10%",
          distributed: true,
          horizontal: true,
          barHeight: 30,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: [],
        title: {
          text: "Images",
          style: {
            fontSize: "12px",
            fontWeight: "normal",
            fontFamily: "Poppins",
            color: "rgba(6, 6, 6, 0.7)",
          },
        },
        labels: {
          style: {
            fontSize: "12px",
            fontWeight: "normal",
            fontFamily: "Poppins",
            color: "rgba(6, 6, 6, 0.7)",
          },
        },
      },
      yaxis: {
        title: {
          text: "Locations",
          style: {
            fontSize: "12px",
            fontWeight: "normal",
            fontFamily: "Poppins",
            color: "rgba(6, 6, 6, 0.7)",
          },
        },
        labels: {
          style: {
            fontSize: "12px",
            fontWeight: "normal",
            fontFamily: "Poppins",
            color: "rgba(6, 6, 6, 0.7)",
          },
        },
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const xValue = w.config.xaxis.categories[dataPointIndex];
          const yValue = series[seriesIndex][dataPointIndex];

          const groupSizeTooltip = `<div class="tooltip-item">Group Size: <span>${xValue}</span></div>`;
          const numberOfImagesTooltip = `<div class="tooltip-item">Number of Images: <span>${yValue}</span></div>`;

          return `<div class="custom-tooltip">${groupSizeTooltip}${numberOfImagesTooltip}</div>`;
        },
      },
    },
  });

  useEffect(() => {
    const response = momentData?.find((res) => res?.moment_id === momentId);
    if (response?.stats?.indoor?.hasOwnProperty("N/A")) {
      delete response?.stats?.indoor["N/A"];
    }
    if (response?.stats?.outdoor?.hasOwnProperty("N/A")) {
      delete response?.stats?.outdoor["N/A"];
    }
    let keysIndoor = response?.stats?.indoor
      ? Object.keys(response?.stats?.indoor)
      : [];

    let keysOutdoor = response?.stats?.outdoor
      ? Object.keys(response?.stats?.outdoor)
      : [];

    let valuesIndoor = response?.stats?.indoor
      ? Object.values(response?.stats?.indoor)
      : [];
    let valuesOutdoor = response?.stats?.outdoor
      ? Object.values(response?.stats?.outdoor)
      : [];

    setChartState((prevState) => ({
      ...prevState,
      series: [{ data: [...valuesIndoor, ...valuesOutdoor] }],
      options: {
        ...prevState.options,
        colors: [
          ...valuesIndoor.map(() => "#F0C274"),
          ...valuesOutdoor.map(() => "#FFAEBC"),
        ],
        xaxis: {
          ...prevState.options.xaxis,
          categories: [...keysIndoor, ...keysOutdoor],
        },
        chart: {
          ...prevState.options.chart,
          toolbar: {
            show: false,
          },
        },
      },
    }));
  }, [momentId]);

  return (
    <div id="chart" className="w-full">
      <ReactApexChart
        options={chartState.options}
        series={chartState.series}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default LocationApexChart;
