import React from "react";
import "./SlidingSquareLoader.css"; // Include the CSS file

const SlidingSquareLoader = () => {
  return (
    <div className="loadingspinner">
      <div id="square1"></div>
      <div id="square2"></div>
      <div id="square3"></div>
      <div id="square4"></div>
    </div>
  );
};

export default SlidingSquareLoader;
