import axios from "axios";
import { getAccessTokenFromStorage } from "./storage";

// Constants
import { BASE_URL } from "../constants/api";
import { auth } from "../config/firebase";

const initialConfig = {
  baseURL: BASE_URL,
  method: "GET",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
};

axios.interceptors.request.use(async (config) => {
  const user = auth.currentUser;

  if (user) {
    const token = await user.getIdToken();
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    console.log("🚀 ~ file: http.js:31 ~ error:", error);
    const originalRequest = error.config;

    // If the error is a 401 and the request hasn't been retried already
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        // Refresh the Firebase token
        await auth.currentUser.getIdToken(true);

        // Retry the original request with the new token
        return axios(originalRequest);
      } catch (refreshError) {
        // If token refresh fails, handle it accordingly (e.g., redirect to login)
        console.error("Token refresh failed:", refreshError);
        // You may want to redirect the user to the login page or show an error message
        throw refreshError;
      }
    }

    return Promise.reject(error);
  }
);

export const request = async (config) => {
  try {
    // const user = auth.currentUser;
    /* const accessToken = getAccessTokenFromStorage(); */
    // const accessToken = user?.accessToken;

    const response = await axios({
      ...initialConfig,
      ...config,
      headers: {
        ...initialConfig.headers,
        ...config?.headers,
        // Authorization: `Bearer ${accessToken}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
