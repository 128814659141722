import { Link, useNavigate } from "react-router-dom";
import imageOutlineIconImage from "../assets/images/png/image-outline-icon.png";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { request } from "../utils/http";
import { BASE_URL_STATUS, BASE_URL } from "../constants/api";
import FullScreenSpinner from "./components/FullScreenSpinner";
import ContainerSpinner from "./components/ContainerSpinner";
import Emotions_Motivations from "./components/Emotions_Motivations";
import Wh51 from "./components/Wh51";
import EmergingThemes from "./components/EmergingThemes";
import BrandRelevance_Marketing from "./components/BrandRelevance_Marketing";
import Donut from "./components/Pie_Moment";
import GroupSizeApexChart from "./components/BarChart_Moment";
import TimeOfDayApexChart from "./components/TimeOfDay_Moment";
import StatsPie from "./components/PieStats";
import GroupSizeStats from "./components/BarChart_Stats";
import TimeOfDayStats from "./components/TimeOfDay_Stats";
import BubbleMoment from "./components/Bubble_Moment";
import Filters, { FilterData } from "./components/Filter";
import { FilterContext } from "./context/FilterContext";
import LocationApexChart from "./components/Location_Moment";
import LocationStats from "./components/Location_Stats";
import StatusResponse_Modal from "./components/StatusResponse_Modal";
import {
  PepSVG,
  ShareSVG,
  FilterSVG,
  DownloadSVG,
  GridSVG,
  CloseSVG,
  SliderRightSideSVG,
  LeftVectorSVG,
  SliderLeftSideSVG,
  DownArrowSVG,
  TextCopySVG,
  LikedSVG,
  DislikedSVG,
  RegenerateSVG,
} from "../svg/Icons";

import logo from "../assets/images/png/logo.png";

export default function LensInsightPage() {
  const navigate = useNavigate();
  const SSE_TIMEOUT_TIME = 5000;
  const POLL_INTERVAL_TIME = 2000;
  const {
    sideBarData,
    setSideBarData,
    displaySideBarData,
    setDisplaySideBarData,
  } = useContext(FilterContext);

  const featuresList = [
    "w5h1",
    "brand_marketing",
    "emotions_and_motivations",
    "brand_relevance",
    "themes",
    "key_themes_details",
    "key_themes",
  ];

  const featuresListForImage = [
    "moment",
    "w5h1",
    "brand_marketing",
    "emotions_and_motivations",
    "brand_relevance",
    "themes",
    "insights",
  ];
  const initialLoading = {
    insights: true,
    w5h1: true,
    key_themes: true,
    is_complete: true,
    brand_marketing: true,
    emotions_and_motivations: true,
    brand_relevance: true,
    themes: true,
    moment: true,
  };
  const initialLoadingForImage = {
    insights: true,
    w5h1: true,
    key_themes: true,
    is_complete: true,
    brand_marketing: true,
    emotions_and_motivations: true,
    brand_relevance: true,
    themes: true,
    moment: true,
  };

  const promptSelectOptions = [
    { title: "moment", value: "Selected Moment", disabled: false },
    { title: "full_image_set", value: "Full Image Set", disabled: true },
    {
      title: "filtered_image_set",
      value: "Filtered Image Set",
      disabled: true,
    },
  ];
  const [isStatusResponseModalVisible, setIsStatusResponseModalVisible] =
    useState(false);
  const [StatusResponseModalData, setStatusResponseModalData] = useState("");
  const sidebarRef = useRef(null);
  const [showGridImage, setShowGridImage] = useState(true);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [initialRender, setInitialRender] = useState(true);
  const [isMomentData, setIsMomentData] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showMomentLoader, SetShowMomentLoader] = useState(false);
  const [containerLoader, setContainerLoader] = useState(false);
  const [imageSetDropdownData, setImageSetDropdownData] = useState([]);
  const [imageNameTitle, setimageNameTitle] = useState("");
  const [images, setImages] = useState([]);
  const [showMoment, SetShowMoment] = useState(false);
  const [momentData, SetMomentData] = useState(null);
  const [imageId, setImageId] = useState(null);
  const [imageName, SetImageName] = useState("");
  const [imageFirstRender, setImageFirstRender] = useState(true);
  const [showMomentData, SetShowMomentData] = useState(false);
  const [showImageData, SetShowImageData] = useState(false);
  const [moment, setMoment] = useState("momentInsights");
  const [momentTitleName, setMomentTitleName] = useState("");
  const [imagesForMoment, setImagesForMoment] = useState(null);
  const [showImageForMoment, setShowImageForMoment] = useState();
  const [imageForMomentLoader, setImageForMomentLoader] = useState(false);
  const [imageSetId, setImageSetId] = useState(null);
  const [loading, setLoading] = useState(initialLoading);
  const [featureData, setFeatureData] = useState({});
  const [featureDataForImage, setFeatureDataForImage] = useState({});
  const [brandRelevance, setBrandRelevance] = useState(true);
  const [marketingOpportunities, setMarketingOpportunities] = useState(false);
  const [emergingThemes, setEmergingThemes] = useState(null);
  const [momentClassName, setmomentClassName] = useState(null);
  const [momentId, setMomentId] = useState(null);
  const [keyThemesTitle, setkeyThemesTitle] = useState("");
  const [promptInputValue, setPromptInputValue] = useState("");
  const [promptError, setPromptError] = useState("");
  const [promptResponse, setPromptResponse] = useState("");
  const [promptSelectedOption, setPromptSelectedOption] = useState("");
  const [propmtResponseLoading, setPropmtResponseLoading] = useState(false);
  const [processedFeatures, setProcessedFeatures] = useState({});
  const [processedFeaturesForImage, setProcessedFeaturesForImage] = useState(
    {}
  );
  const [hideMomentTitle, setHideMomentTitle] = useState(true);
  const [resultData, setResultData] = useState(null);
  const [statsData, SetStatsData] = useState(null);
  const [statuses, setStatuses] = useState({});
  const [statusesForImage, setStatusesForImage] = useState({});
  const [firstRender, setFirstRender] = useState(true);
  const [currentMomentId, setCurrentMomentId] = useState(null);
  const [startIndex, setStartIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const totalPages = Math.ceil(imagesForMoment?.length / itemsPerPage);
  const totalPagesForImage = Math.ceil(images?.length / itemsPerPage);
  const [selectedImageHashId, setSelectedImageHashId] = useState(null);
  const [loadingForImage, setLoadingForImage] = useState(
    initialLoadingForImage
  );
  const [currentMomentChart, setCurrentMomentChart] = useState("gender");
  const [currentStatsChart, setCurrentStatsChart] = useState("gender");
  const [allFilters, setAllFilters] = useState(null);
  const [outdoorFilters, setOutdoorFilters] = useState([]);
  const textareaRef = useRef(null);
  const customBorderColor = [
    "#F7B54480",
    "#D18991",
    "#6DBEBD",
    "#9BBDED",
    "#9297C7",
    "#EBB0CD",
    "#FFF199",
  ];
  const customBgColors = [
    "#FDF4EAA6",
    "#F4BAC1",
    "#B2E7E4",
    "#C8D9F3",
    "#D1D4E2",
    "#F1D8E6",
    "#FFFACD",
  ];
  const [borderColor, setBorderColor] = useState(() => customBorderColor[0]);
  const [bgColor, setBgColor] = useState(() => customBgColors[0]);
  const handleCutFilter = (res, filterName) => {
    const updatedData = sideBarData.map((data) => {
      if (data?.key === filterName) {
        return {
          ...data,
          children: data?.children?.map((value) => {
            if (value.value === res) {
              return { ...value, isChecked: false };
            }
            return value;
          }),
        };
      }
      return data;
    });

    if (displaySideBarData.hasOwnProperty(filterName)) {
      let newData = displaySideBarData[filterName]?.filter(
        (value) => value !== res
      );

      newData &&
        setDisplaySideBarData((prev) => ({ ...prev, [filterName]: newData }));
    }

    setSideBarData(updatedData);
  };

  const getFilterContent = async (imgSetId) => {
    const response = await request({
      url: `/image_set_analysis/${imgSetId}/filters`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (response && response.status === 200) {
      setAllFilters(response.data);
      setOutdoorFilters(Object.keys(response.data.outdoor));
    }

    // setShowLoader(false);
  };

  const getImages = async (imgSetId, filters = { filters: {} }) => {
    const response = await request({
      url: `/image_set_analysis/${imgSetId}/images`,
      method: "POST",
      data: filters,
    });

    if (response && response.status === 200) {
      setImageId(response.data[0]);
      setImages(response.data);
      // setImageUrlArray(
      //   response.data.map((image) => ({
      //     id: image.image_hash,
      //     imgUrl: image.public_url,
      //   }))
      // );
    }

    // setShowLoader(false);
  };

  const applyFilter = (appliedFilters) => {
    if (!imageSetId) return false;
    getImages(imageSetId, { filters: appliedFilters });
    getMoments(imageSetId, { filters: appliedFilters });
    getStats(imageSetId, { filters: appliedFilters });
  };

  const resetFilter = () => {
    getImagesFromImageSets(imageNameTitle);
    getLatestCompleted(imageNameTitle);
    SetShowMomentData(false);
    setMoment("momentInsights");
  };
  const handleDropdownClick = (event) => {
    if (
      event.target.closest("#image-set-dropdown").id === "image-set-dropdown" &&
      event.target.closest("#image-set-dropdown-option")?.id !==
        "image-set-dropdown-option"
    ) {
      setDropdownOpen(!isDropdownOpen);
    }
  };

  const handleMarketingOpportunities = () => {
    setBrandRelevance(false);
    setMarketingOpportunities(true);
  };

  const handleBrandRelevance = () => {
    setMarketingOpportunities(false);
    setBrandRelevance(true);
  };

  const handleEmergingThemes = (index, title) => {
    setEmergingThemes(index);
    setkeyThemesTitle(title);
    setFirstRender(false);
  };

  const handleSelectOptionChange = (e) => {
    setPromptSelectedOption(e.target.value);
    setPromptError("");
    setPromptResponse("");
  };

  const handlePromptInputChange = (e) => {
    setPromptInputValue(e.target.value);
    setPromptError("");
    setPromptResponse("");
  };

  const handleMomentImageClick = (res) => {
    setShowImageForMoment(res?.public_url);
  };
  const handleSingleImageClick = async (res) => {
    setFirstRender(true);
    setMarketingOpportunities(false);
    setBrandRelevance(true);
    setEmergingThemes(null);
    setImageFirstRender(false);
    setShowImageForMoment(res?.public_url);
    setPromptError("");
    setPromptResponse("");
    setPropmtResponseLoading(false);
    setPromptInputValue("");
    try {
      setLoadingForImage(initialLoadingForImage);
      setProcessedFeaturesForImage({});
      let result = await getImageId(res?.image_hash);
      await getResult(result);
      await getImageInfo(result);
    } catch (error) {
      console.error(error);
    } finally {
      // setShowLoader(false);
    }
  };

  const next = (momentImage) => {
    if (momentImage === "momentImage") {
      if (startIndex + itemsPerPage < imagesForMoment?.length) {
        setStartIndex(startIndex + itemsPerPage);
      }
    } else {
      if (startIndex + itemsPerPage < images?.length) {
        setStartIndex(startIndex + itemsPerPage);
      }
    }
  };

  const previous = () => {
    if (startIndex - itemsPerPage >= 0) {
      setStartIndex(startIndex - itemsPerPage);
    }
  };

  const getImageSetDropData = async () => {
    setShowLoader(true);
    const response = await request({
      method: "GET",
      url: "image_sets/",
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (response && response.status === 200) {
      setImageSetDropdownData(response.data);
    }

    setShowLoader(false);
  };

  const getImagesFromImageSets = async (setName) => {
    try {
      setContainerLoader(true);
      const response = await request({
        url: `/image_sets/${setName}`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setImageId(response.data[0]);
      setImages(response.data);
      return response;
    } catch (error) {
      console.log(error);
    } finally {
      setContainerLoader(false);
    }
  };

  const getStatus = async (id) => {
    const main = async () => {
      const response = await request({
        url: `/image_set_analysis/${id}/status`,
      });

      if (response && response.status === 200) {
        if (response && response.data?.is_complete === true) {
          setIsStatusResponseModalVisible(false);
          clearInterval(intervalId);
          await getMoments(id);
          await getStats(id);
          await getFilterContent(id);
          await getImages(id);
        } else {
          setStatusResponseModalData(response.data?.status_str);
          setIsStatusResponseModalVisible(true);
        }
      }
    };

    main();

    let intervalId = setInterval(main, 5000);
  };

  const getLatest = async (iSetId) => {
    try {
      const response = await request({
        url: `/image_sets/${iSetId}/latest`,
      });

      if (response && response.status === 200) {
        setImageSetId(response.data);
        setPromptError("");
        setPromptResponse("");
        getStatus(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getLatestCompleted = async (iSetId) => {
    try {
      SetShowMomentLoader(true);
      const response = await request({
        url: `/image_sets/${iSetId}/latest_completed`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response && response.status === 200) {
        setImageSetId(response.data);
        setPromptError("");
        setPromptResponse("");
        await getFilterContent(response.data);
        await getImages(response.data);
        await getMoments(response.data);
        await getStats(response.data);
      }
    } catch (error) {
      if (error.response?.status === 404) {
        getLatest(iSetId);
      }
    }
  };

  const getMoments = async (imgSetId, appliedFilters = { filters: {} }) => {
    SetShowMomentLoader(true);

    const response = await request({
      method: "POST",
      url: `/image_set_analysis/${imgSetId}/moments`,
      data: appliedFilters,
    });

    if (response && response.status === 200) {
      SetMomentData(response.data.sort((a, b) => b.size - a.size));
    }
    SetShowMomentLoader(false);
  };

  const getImageInfo = async (imageId) => {
    const response = await request({
      url: `/image/${imageId}/info`,
    });

    if (response) {
      SetImageName(response.data);
    }
  };

  const getStats = async (imgSetId, appliedFilters = { filters: {} }) => {
    // SetShowMomentLoader(true);

    const response = await request({
      method: "POST",
      url: `/image_set_analysis/${imgSetId}/stats`,
      data: appliedFilters,
    });

    if (response && response.status === 200) {
      SetStatsData(response.data);
    }
    // SetShowMomentLoader(false);
  };

  const startMoment = async (moment_id) => {
    // setContainerLoader(true);
    const response = await request({
      method: "POST",
      url: `/image_set_analysis/image_set/${imageSetId}/moment/${moment_id}/start?cache=true`,
      data: {},
    });

    if (response && response.status === 200) {
    }
    // setContainerLoader(false);
  };

  const getImagesForMoment = async (res) => {
    setImageForMomentLoader(true);
    try {
      const response = await request({
        url: `/image_set_analysis/image_set/${imageSetId}/moment/${res}/images`,
      });

      if (response) {
        setImagesForMoment(response.data);
        setShowImageForMoment(response.data[0]?.public_url);
        setImageForMomentLoader(false);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const onMomentClick = async (moment_id) => {
    setCurrentMomentId(moment_id);
    setPromptError("");
    setPromptResponse("");
    setPromptInputValue("");
    setPromptSelectedOption("");
    setLoading(initialLoading);
    setStatuses({});
    setFeatureData({});
    setProcessedFeatures({});
    setFirstRender(true);
    setBrandRelevance(true);
    setMarketingOpportunities(false);
    setEmergingThemes(null);
    setCurrentStatsChart("gender");
    setCurrentMomentChart("gender");
    setStartIndex(0);
    setLoadingForImage({
      insights: false,
      w5h1: false,
      key_themes: false,
      is_complete: false,
      brand_marketing: false,
      emotions_and_motivations: false,
      brand_relevance: false,
      themes: false,
      moment: false,
    });

    await startMoment(moment_id);
    getStatusAPI(moment_id);
  };

  const handleMomentClick = (res, index) => {
    setBgColor(customBgColors[index]);
    setBorderColor(customBorderColor[index % customBorderColor.length]);
    setMomentId(res?.moment_id);
    setInitialRender(false);
    setIsMomentData(true);
    SetShowMomentData(true);
    onMomentClick(res?.moment_id);
    getImagesForMoment(res?.moment_id);
    setmomentClassName(res?.moment_id);
    setMomentTitleName(res?.moment_name);
  };

  const handleGoBackImageClick = () => {
    setInitialRender(true);
    SetShowMoment(true);
    SetShowImageData(false);
    setShowGridImage(true);
    setmomentClassName(null);
    setImageFirstRender(true);
    setMoment("momentInsights");
    setPromptError("");
    setPromptResponse("");
    setPropmtResponseLoading(false);
    setPromptInputValue("");
    setPromptSelectedOption("");
    setHideMomentTitle(true);
    setCurrentMomentId(null);
    setCurrentStatsChart("gender");
    handleMomentClick(momentData[0], 0);
  };

  const getStatusAPI = async (momentId) => {
    let timeoutId = null;
    let eventSource = null;

    const handleSSE = (event) => {
      clearTimeout(timeoutId);

      const data = JSON.parse(event.data);

      setStatuses(data);

      if (data?.is_complete) {
        eventSource.close();
      }
    };

    const handleSSEError = (event) => {
      console.error(event);
      eventSource.close();
    };

    const switchToLongPolling = () => {
      const main = async () => {
        const response = await request({
          url: `image_set_analysis/image_set/${imageSetId}/moment/${momentId}/status`,
        });

        setStatuses(response.data);

        if (response.data.is_complete) {
          clearInterval(intervalId);
        }
      };

      main();

      let intervalId = setInterval(main, POLL_INTERVAL_TIME);
    };

    const createSSE = () => {
      eventSource = new EventSource(
        `${BASE_URL_STATUS}/image_set_analysis/image_set/${imageSetId}/moment/${momentId}/status_notifications`
      );

      eventSource.addEventListener("message", handleSSE);
      eventSource.addEventListener("error", handleSSEError);

      timeoutId = setTimeout(() => {
        eventSource.close();
        switchToLongPolling();
      }, SSE_TIMEOUT_TIME);
    };

    const response = await request({
      url: `image_set_analysis/image_set/${imageSetId}/moment/${momentId}/status`,
    });

    setStatuses(response.data);

    if (!response.data.is_complete) {
      switchToLongPolling();
    }
  };

  const getResultAPI = async (momentId, feature) => {
    try {
      setLoading((prev) => ({ ...prev, [feature]: true }));

      const response = await request({
        url: `/image_set_analysis/image_set/${imageSetId}/moment/${momentId}/result/${feature}`,
      });

      setFeatureData((prev) => ({ ...prev, [feature]: response?.data }));

      return response.data;
    } catch (error) {
      console.log(error);
    } finally {
      setLoading((prev) => ({ ...prev, [feature]: false }));
    }
  };

  const handlePromptSubmit = (e) => {
    e.preventDefault();

    if (!imageSetId) {
      setPromptError("No image set is selected");
      return;
    }
    if (!currentMomentId) {
      setPromptError("No moment is selected");
      return;
    }

    if (!promptSelectedOption) {
      setPromptError("No prompt option is selected");
      return;
    }

    if (!promptInputValue) {
      setPromptError("No prompt input found");
      return;
    }

    setPromptError("");
    setPromptResponse("");
    setPropmtResponseLoading(true);

    const newEventSource = new EventSource(
      `${BASE_URL}/image_set_analysis/image_set/${imageSetId}/moment/${currentMomentId}/chat?prompt=${promptInputValue}&level=${promptSelectedOption}`
    );

    newEventSource.onmessage = (event) => {
      setPropmtResponseLoading(false);
      setPromptResponse((prev) => prev + event.data);
    };

    newEventSource.onerror = (event) => {
      console.error(event);
      newEventSource.close();
    };
  };

  const handlePromptSubmitForImage = (e) => {
    e.preventDefault();

    if (!selectedImageHashId) {
      setPromptError("No image is selected");
      return;
    }

    if (!promptInputValue) {
      setPromptError("No prompt input found");
      return;
    }

    setPromptError("");
    setPromptResponse("");
    setPropmtResponseLoading(true);

    const newEventSource = new EventSource(
      `${BASE_URL}/image/${selectedImageHashId}/chat?prompt=${promptInputValue}`
    );

    newEventSource.onmessage = (event) => {
      setPropmtResponseLoading(false);
      setPromptResponse((prev) => prev + event.data);
    };

    newEventSource.onerror = (event) => {
      console.error(event);
      newEventSource.close();
    };
  };

  const getStatusAPIForImage = async (imageId) => {
    let timeoutId = null;
    let eventSource = null;

    const handleSSE = (event) => {
      clearTimeout(timeoutId);

      const data = JSON.parse(event.data);

      setStatusesForImage(response.data);

      if (data?.is_complete) {
        eventSource.close();
      }
    };

    const handleSSEError = (event) => {
      console.error(event);
      eventSource.close();
    };

    const switchToLongPolling = () => {
      const main = async () => {
        const response = await request({
          url: `image/${imageId}/status`,
        });

        setStatusesForImage(response.data);

        if (response.data.is_complete) {
          clearInterval(intervalId);
        }
      };

      main();

      let intervalId = setInterval(main, POLL_INTERVAL_TIME);
    };

    const createSSE = () => {
      eventSource = new EventSource(
        `${BASE_URL_STATUS}/image/${imageId}/status_notifications`
      );

      eventSource.addEventListener("message", handleSSE);
      eventSource.addEventListener("error", handleSSEError);

      timeoutId = setTimeout(() => {
        eventSource.close();
        switchToLongPolling();
      }, SSE_TIMEOUT_TIME);
    };

    const response = await request({
      url: `image/${imageId}/status`,
    });

    setStatusesForImage(response.data);

    if (!response.data.is_complete) {
      switchToLongPolling();
    }
  };

  const getImageId = async (imageHash) => {
    try {
      // setShowLoader(true);
      const response = await request({
        method: "POST",
        url: `/image/${imageHash}`,
        data: {},
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setSelectedImageHashId(response?.data?.id || null);
      // await getStatus(response.data.id);
      await getStatusAPIForImage(response.data.id);
      return response?.data?.id;
    } catch (error) {
      console.log(error);
    } finally {
      setShowLoader(false);
    }
  };

  const handleImageClick = async (res) => {
    setImageId(res);
    setInitialRender(false);
    setIsMomentData(false);
    SetShowImageData(true);
    setFirstRender(true);
    setEmergingThemes(null);
    setBrandRelevance(true);
    SetShowMomentData(false);
    setMarketingOpportunities(false);
    setPromptError("");
    setPromptResponse("");
    setPromptInputValue("");
    setLoading({
      insights: false,
      w5h1: false,
      key_themes: false,
      is_complete: false,
      brand_marketing: false,
      emotions_and_motivations: false,
      brand_relevance: false,
      themes: false,
      moment: false,
    });
    setMoment("momentInsights");
    setHideMomentTitle(false);
    setStartIndex(0);

    try {
      /* setShowLoader(true);
      if (selectedImage) {
        setSelectedImage(null);
      }
      if (selectedImageId !== imageId) {
        setLoading(initialLoading);
        setSelectedImageId(imageId);
        setProcessedFeatures({});
        setStatuses({});
        setUploadedImageId(null);
        await getImageId(imageId);
      } else {
        setSelectedImageId(null); // Deselect the image if clicked again
        setSelectedImage(null); // Clear the selected image
      } */
      setLoadingForImage(initialLoadingForImage);
      setProcessedFeaturesForImage({});
      let imageHashId = await getImageId(res?.image_hash);
      await getResult(imageHashId);
      await getImageInfo(imageHashId);
    } catch (error) {
      console.error(error);
    } finally {
      // setShowLoader(false);
    }
  };

  const getResultAPIForImage = async (imageId, feature) => {
    try {
      setLoadingForImage((prev) => ({ ...prev, [feature]: true }));

      const response = await request({
        url: `/image/${imageId}/result/${feature}`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setFeatureDataForImage((prev) => ({
        ...prev,
        [feature]: response?.data,
      }));

      return response.data;
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingForImage((prev) => ({ ...prev, [feature]: false }));
    }
  };

  const getResult = async (imageHash) => {
    try {
      const response = await request({
        url: `/image/${imageHash}/result/stats `,
      });
      response && setResultData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCopyClick = () => {
    const textarea = textareaRef.current;

    if (textarea) {
      textarea.select();

      try {
        document.execCommand("copy");
      } catch (err) {
        console.error("Unable to copy text to clipboard", err);
      }

      textarea.setSelectionRange(0, 0);
    }
  };

  useEffect(() => {
    const main = (imageId) => {
      for (let i = 0; i < featuresListForImage.length; i++) {
        const feature = featuresListForImage[i];

        if (statusesForImage[feature] && !processedFeaturesForImage[feature]) {
          setProcessedFeaturesForImage((prev) => ({
            ...prev,
            [feature]: true,
          }));

          getResultAPIForImage(imageId, feature);
        }
      }
    };

    /* if (uploadedImageId) {
      main(uploadedImageId);
    } else if (selectedImageHashId) {
      main(selectedImageHashId);
    } */
    if (selectedImageHashId) {
      main(selectedImageHashId);
    }
  }, [selectedImageHashId /* uploadedImageId */, , statusesForImage]);

  useEffect(() => {
    const main = (momentId) => {
      for (let i = 0; i < featuresList.length; i++) {
        const feature = featuresList[i];

        if (statuses[feature] && !processedFeatures[feature]) {
          setProcessedFeatures((prev) => ({ ...prev, [feature]: false }));

          getResultAPI(momentId, feature);
        }
      }
    };

    if (currentMomentId) {
      main(currentMomentId);
    }
  }, [currentMomentId, statuses]);

  useEffect(() => {
    getImageSetDropData();
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsOpenFilter(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (
      imageNameTitle &&
      momentData &&
      momentData.length > 0 &&
      momentData[0] &&
      momentData[0].moment_id
    ) {
      handleMomentClick(momentData[0], 0);
    }
  }, [momentData]);

  useEffect(() => {
    const handleResize = () => {
      // Adjust the number of items based on screen width
      if (window.innerWidth <= 575) {
        setItemsPerPage(4);
      } else {
        setItemsPerPage(8);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  return (
    <>
      {showLoader && <FullScreenSpinner />}
      <div className="mx-auto min-h-screen container-xl text-[#060606]">
        <div className="py-3">
          <Link to="/" className="w-[100px] h-12 block">
            <img
              src={logo}
              alt="Logo of the Lens app"
              className="w-full h-full object-contain"
            />
          </Link>
        </div>
        <div className="flex flex-col sm:flex-row justify-between items-center pb-2">
          <h4 className="text-[24px] mb-3 md:mb-0 font-medium text-[#060606]">
            Image Analytics
          </h4>
          <div
            id="image-set-dropdown"
            className="md:col-span-2 py-2 w-full sm:w-[200px] h-[60px] relative px-3 border-[1px] borer-[#ECE5E5] cursor-pointer select-none flex items-center justify-between rounded-lg"
            onClick={handleDropdownClick}
          >
            <div>
              <p className="text-[#717171CC] text-[12px] font-normal">
                Change set
              </p>
              <p className="text-[#070B32] text-[16px] font-normal">
                {imageNameTitle}
              </p>
            </div>
            <DownArrowSVG />
            {isDropdownOpen && (
              <div
                id="image-set-dropdown-option"
                className="dropdown absolute w-100 bg-white rounded-md p-3 shadow-lg top-16 left-0 z-10"
              >
                <ul className="max-h-[200px] overflow-auto scroll-element">
                  {imageSetDropdownData &&
                    imageSetDropdownData.length > 0 &&
                    imageSetDropdownData.map((response, index) => {
                      return (
                        <li className="custom-radio mb-3" key={index}>
                          <label className="custom-radio flex items-center justify-between">
                            <input
                              type="radio"
                              checked={response === imageNameTitle}
                              value={response}
                              onChange={(e) => {
                                SetShowMoment(true);
                                setDropdownOpen(false);
                                setShowGridImage(true);
                                setimageNameTitle(e.target.value);
                                getImagesFromImageSets(e.target.value);
                                getLatestCompleted(e.target.value);
                              }}
                            />
                            {response}
                            <span></span>
                          </label>
                        </li>
                      );
                    })}
                </ul>
                <div className="pt-3 border-t-[1px] border-[#ECE5E5] text-right">
                  <button
                    className="bg-[#070B32] text-[12px] text-white font-normal rounded-md py-2 px-3"
                    onClick={() => navigate("/upload_image")}
                  >
                    Create Project
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex sm:h-[60.8px] border-[1px] border-[#ECE5E5] border-b-[0] rounded-tl-lg rounded-tr-lg">
          <div
            className="flex-none flex items-center justify-between py-2 px-3 border-r-[1px] borer-[#ECE5E5] relative"
            ref={sidebarRef}
          >
            <div>
              <p className="flex items-center">
                <span className="mr-3 text-[#060606] text-[14px] sm:text-[18px] font-normal">
                  Filter
                </span>
                {Object.keys(displaySideBarData)?.length > 0 && (
                  <>
                    {Object.keys(displaySideBarData).filter(
                      (key) => displaySideBarData[key].length > 0
                    ).length > 0 && (
                      <span className="w-5 h-5 flex items-center justify-center text-center  rounded-full text-[#fff] text-[12px] font-semibold bg-[#674CB4D9]">
                        {
                          Object.keys(displaySideBarData).filter(
                            (key) => displaySideBarData[key].length > 0
                          ).length
                        }
                      </span>
                    )}
                  </>
                )}
              </p>
            </div>
            <div className="h-full ml-4">
              <button
                className="h-full"
                aria-hidden="true"
                onClick={() => setIsOpenFilter(!isOpenFilter)}
              >
                <FilterSVG />
              </button>
            </div>
            <Filters
              allFilters={allFilters}
              applyFilter={applyFilter}
              resetFilter={resetFilter}
              outdoorFilters={outdoorFilters}
              setImageFirstRender={setImageFirstRender}
              isOpenFilter={isOpenFilter}
              setIsOpenFilter={setIsOpenFilter}
              SetShowMomentData={SetShowMomentData}
            />
          </div>
          <div className="flex-auto sm:col-span-3 lg:col-span-3 border-y-[1px] border-[#ebebeb]  md:border-y-[0px]">
            {Object.keys(displaySideBarData)?.length > 0 && (
              <div className=" p-3 flex overflow-auto w-full">
                {displaySideBarData?.group_size?.length > 0 && (
                  <div className="flex items-center h-[100%]">
                    <h6 className="text-[#060606] opacity-60 whitespace-nowrap pr-2">
                      Group size
                    </h6>
                    <div className="flex overflow-x-auto flex-nowrap scroll-none max-w-[180px]">
                      {displaySideBarData?.group_size?.map((res, index) => {
                        return (
                          <span
                            className="h-[31px] border-[1px] border-[#7130a580] px-[12px] rounded-full flex items-center justify-between ml-2 text-[14px] whitespace-nowrap"
                            key={index}
                            onClick={() => handleCutFilter(res, "group_size")}
                          >
                            {res}
                            <CloseSVG />
                          </span>
                        );
                      })}
                    </div>
                  </div>
                )}

                {displaySideBarData?.gender?.length > 0 && (
                  <div className="flex items-center h-[100%] ml-8">
                    <h6 className="text-[#060606] opacity-60 whitespace-nowrap">
                      Gender
                    </h6>
                    <div className="flex overflow-x-auto flex-nowrap scroll-none max-w-[180px]">
                      {displaySideBarData?.gender?.map((res) => {
                        return (
                          <span
                            className="h-[31px] border-[1px] border-[#7130a580] px-[12px] rounded-full flex items-center justify-between ml-2 text-[14px] whitespace-nowrap"
                            onClick={() => handleCutFilter(res, "gender")}
                          >
                            {res}
                            <CloseSVG />
                          </span>
                        );
                      })}
                    </div>
                  </div>
                )}

                {displaySideBarData?.time_of_day?.length > 0 && (
                  <div className="flex items-center h-[100%] ml-8">
                    <h6 className="text-[#060606] opacity-60 whitespace-nowrap">
                      Time of day
                    </h6>
                    <div className="flex overflow-x-auto flex-nowrap scroll-none max-w-[180px]">
                      {displaySideBarData?.time_of_day?.map((res) => {
                        return (
                          <span
                            className="h-[31px] border-[1px] border-[#7130a580] px-[12px] rounded-full flex items-center justify-between ml-2 text-[14px] whitespace-nowrap"
                            onClick={() => handleCutFilter(res, "time_of_day")}
                          >
                            {res}
                            <CloseSVG />
                          </span>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="flex-none flex justify-end">
            <div className="w-full md:w-[120px] h-[100%] flex items-center justify-around py-2 px-3 border-l-[1px] border-[#ECE5E5]">
              <button>
                <ShareSVG />
              </button>
              <button>
                <DownloadSVG />
              </button>
            </div>
          </div>
        </div>
        <div className="mb-5 main-section border border-[#ECE5E5] border-t-[0] rounded-bl-lg rounded-br-lg">
          <div className="grid grid-cols-1 lg:grid-cols-2">
            <div className="border-r-[1px] borer-[#ECE5E5]">
              <div className="h-[58px] p-3 flex items-center justify-between text-[20px] font-medium border-b-[1px] border-[#ECE5E5] text-[#060606] bg-[#7130a51a]">
                <h5 className="text-[20px] text-[060606] font-medium">
                  Bira set 1
                  <span className="opacity-70 text-[16px] ml-2 font-normal">
                    {`(${images?.length})`}
                  </span>
                </h5>
              </div>

              {initialRender || isMomentData ? (
                <div className="p-3">
                  <div className=" h-[200px] lg:h-[350px] overflow-auto scroll-element relative">
                    {containerLoader && (
                      <div className="content-spinner">
                        <ContainerSpinner />
                      </div>
                    )}
                    <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 gap-3 pr-4">
                      {images &&
                        images.map((res, index) => {
                          return (
                            <img
                              src={res?.public_url}
                              className="h-[106px] w-[106px] object-cover rounded-[5.09px] cursor-pointer"
                              alt=""
                              key={index}
                              onClick={() => handleImageClick(res)}
                            />
                          );
                        })}
                    </div>
                  </div>
                  {images.length > 0 && (
                    <span className="text-[14px] font-normal text-[#070B32] opacity-70 block text-right pt-3">
                      Click an image to view individual statistics
                    </span>
                  )}
                </div>
              ) : (
                <>
                  <div className="p-3 pb-0 text-center lg:ml-10 flex justify-center items-center flex-col relative">
                    {imageForMomentLoader && (
                      <div className="content-spinner">
                        <ContainerSpinner />
                      </div>
                    )}
                    <div className="flex items-center w-full px-4 mb-3">
                      <div onClick={handleGoBackImageClick}>
                        <LeftVectorSVG />
                      </div>
                      <p className="text-[12px] mb-0 ml-3">
                        Go back to view entire set
                      </p>
                    </div>
                    <div className="flex">
                      <img
                        src={
                          imageFirstRender
                            ? imageId?.public_url
                            : showImageForMoment
                        }
                        className="h-[317px] object-cover rounded-lg"
                      />
                    </div>
                    <div className="flex items-center mt-3 mb-5">
                      <div>
                        <div onClick={previous}>
                          <SliderLeftSideSVG startIndex={startIndex} />
                        </div>
                      </div>
                      <div className="flex gap-2 overflow-x-auto justify-center w-[300px] md:w-[470px]">
                        {images &&
                          images
                            .slice(startIndex, startIndex + itemsPerPage)
                            .map((image, index) => (
                              <img
                                src={image?.public_url}
                                alt=""
                                key={index}
                                className={`h-[47px] cursor-pointer rounded-md ${
                                  image?.public_url ===
                                  (imageFirstRender
                                    ? imageId?.public_url
                                    : showImageForMoment)
                                    ? "slider-img-active"
                                    : "slider-img"
                                }`}
                                onClick={() => handleSingleImageClick(image)}
                              />
                            ))}
                      </div>
                      <div>
                        <div className="text-center relative">
                          <div onClick={next}>
                            <SliderRightSideSVG
                              startIndex={startIndex}
                              itemsPerPage={itemsPerPage}
                              totalPagesForImage={totalPagesForImage}
                            />
                          </div>
                          <span className="text-xs absolute inline-block whitespace-nowrap left-[20%] translate-x-[-50%] bottom-[-30px]">{`${
                            Math.floor(startIndex) + 1
                          }-${
                            itemsPerPage + startIndex > images?.length
                              ? images?.length
                              : itemsPerPage + startIndex
                          } of ${images?.length}`}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div>
              <h4 className="h-[58px] p-3 flex max-md:flex-col gap-x-3 text-[20px] font-normal border-b-[1px] border-[#ECE5E5] text-[#060606] bg-[#7130a51a]">
                <div className="pl-3 max-md:pl-0 flex-1 flex items-center justify-between">
                  <p className="mb-0 text-[20px] text-[060606] font-medium">
                    Image Statistics
                  </p>
                  {initialRender || isMomentData ? (
                    <span className="mr-1 text-[16px] font-medium">
                      {images?.length}
                      <span className="ml-1 text-[#060606b3] font-normal">
                        images
                      </span>
                    </span>
                  ) : (
                    <div className="hover-container">
                      {imageName?.image_name && (
                        <span
                          className="mr-1 text-[16px] font-medium"
                          title={imageName.image_name}
                        >
                          {`${imageName.image_name.slice(0, 20)}...png`}
                        </span>
                      )}
                    </div>
                  )}
                </div>
              </h4>
              {initialRender || isMomentData ? (
                <div className="p-3">
                  <ul className="flex pt-2">
                    <li
                      className={`font-normal text=[#060606] text-[16px] sm:text-[16px] cursor-pointer border-b-[1.53px] pb-1 ${
                        currentStatsChart === "gender"
                          ? "border-[#674CB4]"
                          : "opacity-60"
                      }`}
                      onClick={() => setCurrentStatsChart("gender")}
                    >
                      Gender
                    </li>
                    <li
                      className={`font-normal text=[#060606] text-[16px] sm:text-[16px] cursor-pointer  ml-4 sm:ml-7 border-b-[1.53px] pb-1 ${
                        currentStatsChart === "groupSize"
                          ? "border-[#674CB4]"
                          : "opacity-60"
                      }`}
                      onClick={() => setCurrentStatsChart("groupSize")}
                    >
                      Group size
                    </li>
                    <li
                      className={`font-normal text=[#060606] text-[16px] sm:text-[16px] cursor-pointer  ml-4 sm:ml-7 border-b-[1.53px] pb-1 ${
                        currentStatsChart === "time"
                          ? "border-[#674CB4]"
                          : "opacity-60"
                      }`}
                      onClick={() => setCurrentStatsChart("time")}
                    >
                      Time of day
                    </li>
                    <li
                      className={`font-normal text=[#060606] text-[16px] sm:text-[16px] cursor-pointer  ml-4 sm:ml-7 border-b-[1.53px] pb-1 ${
                        currentStatsChart === "location"
                          ? "border-[#674CB4]"
                          : "opacity-60"
                      }`}
                      onClick={() => setCurrentStatsChart("location")}
                    >
                      Location
                    </li>
                  </ul>
                  <div className="relative h-[365px] flex items-center justify-center">
                    {showMoment && (
                      <>
                        {!statsData && (
                          <div className="content-spinner">
                            <ContainerSpinner />
                          </div>
                        )}
                      </>
                    )}
                    {statsData && Object.keys(statsData).length > 0 && (
                      <>
                        {currentStatsChart === "gender" && (
                          <StatsPie statsData={statsData} />
                        )}
                        {currentStatsChart === "groupSize" && (
                          <GroupSizeStats statsData={statsData} />
                        )}
                        {currentStatsChart === "time" && (
                          <TimeOfDayStats statsData={statsData} />
                        )}
                        {currentStatsChart === "location" && (
                          <LocationStats statsData={statsData} />
                        )}
                      </>
                    )}
                  </div>
                </div>
              ) : (
                <div className="p-3">
                  <div className="py-2 flex justify-between">
                    <p className="text-[16px] text-[#060606b3]">Gender</p>
                    <p className="font-medium text-[#060606]">
                      {resultData?.gender}
                    </p>
                  </div>
                  <div className="py-2 flex justify-between">
                    <p className="text-[16px] text-[#060606b3]">Group size</p>
                    <p className="font-medium text-[#060606]">
                      {resultData?.group_size}
                    </p>
                  </div>
                  <div className="py-2 flex justify-between">
                    <p className="text-[16px] text-[#060606b3]">Time of day</p>
                    <p className="font-medium text-[#060606]">
                      {resultData?.time_of_day}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="relative">
            {showMomentLoader && (
              <div className="content-spinner">
                <ContainerSpinner />
              </div>
            )}
            {showMoment && (
              <>
                <>
                  {" "}
                  <h4 className="p-3 text-[20px] font-medium border border-[#ECE5E5] text-[#060606] bg-[#7130a51a]">
                    Moments
                  </h4>
                  {hideMomentTitle && (
                    <>
                      {momentData && (
                        <h5 className="text-[#060606] text-[18px] font-medium py-4 px-3 border-b border-[#ECE5E5] sticky top-0 bg-white z-10">
                          {momentTitleName} ({imagesForMoment?.length})
                        </h5>
                      )}
                      <div className="px-3 tabs">
                        <ul className="flex pt-4">
                          <li
                            className={`font-medium text=[#060606] text-[16px] cursor-pointer ${
                              moment === "momentInsights"
                                ? "border-b-[1.53px] pb-1 border-[#674CB4]"
                                : "opacity-50"
                            }`}
                            onClick={() => {
                              setMoment("momentInsights");
                              SetShowMomentData(true);
                              setPromptError("");
                              setPromptResponse("");
                              setPropmtResponseLoading(false);
                              setPromptInputValue("");
                              setPromptSelectedOption("");
                            }}
                          >
                            Moments Insights
                          </li>
                          <li
                            className={`font-medium text=[#060606] text-[16px] ml-4 sm:ml-7 cursor-pointer ${
                              moment === "momentsChart"
                                ? "border-b-[1.53px] pb-1 border-[#674CB4]"
                                : "opacity-50"
                            }`}
                            onClick={() => {
                              setMoment("momentsChart");
                              SetShowMomentData(false);
                              setPromptError("");
                              setPromptResponse("");
                              setPropmtResponseLoading(false);
                              setPromptInputValue("");
                              setPromptSelectedOption("");
                            }}
                          >
                            Moments Chart
                          </li>
                        </ul>
                        {moment === "momentInsights" && (
                          <div className="scroll-element scroll-element-x w-[100%] overflow-x-auto">
                            <ul className="w-[1500px] flex gap-3 py-3 mt-2">
                              {momentData &&
                                momentData.map((res, index) => {
                                  return (
                                    <li
                                      className={`h-[104px] items-center rounded-[8px]  cursor-pointer moment-insights w-[278px] border-2 border-[#ECE5E5] p-3 ${
                                        momentClassName == res?.moment_id
                                          ? "active"
                                          : ""
                                      }`}
                                      style={
                                        momentClassName == res?.moment_id
                                          ? {
                                              border: `2px solid ${borderColor}`,
                                              backgroundColor: bgColor,
                                            }
                                          : {
                                              border: "",
                                              backgroundColor: "",
                                            }
                                      }
                                      key={index}
                                      onClick={() =>
                                        handleMomentClick(res, index)
                                      }
                                    >
                                      <span
                                        style={
                                          momentClassName == res?.moment_id
                                            ? {
                                                background: borderColor,
                                              }
                                            : {
                                                background: "",
                                              }
                                        }
                                      ></span>
                                      <p className="text-[14px] font-normal text-[#060606]">
                                        {`${res?.moment_name} (${res.size})`}
                                      </p>
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                        )}
                      </div>{" "}
                    </>
                  )}
                  {moment === "momentsChart" && (
                    <>
                      <BubbleMoment momentData={momentData} />
                    </>
                  )}
                  {showMomentData && (
                    <>
                      <div className="grid grid-cols-1 lg:grid-cols-2">
                        {
                          <div className="p-3 text-center lg:ml-10 flex justify-center items-center flex-col relative">
                            {imageForMomentLoader && (
                              <div className="content-spinner">
                                <ContainerSpinner />
                              </div>
                            )}

                            <img
                              src={showImageForMoment}
                              className="h-[317px]"
                            />

                            <div className="flex items-center mt-3 mb-5">
                              <div>
                                <div onClick={previous}>
                                  <SliderLeftSideSVG startIndex={startIndex} />
                                </div>
                              </div>
                              <div className="flex gap-2 overflow-x-hidden justify-center mx-2">
                                {imagesForMoment &&
                                  imagesForMoment
                                    .slice(
                                      startIndex,
                                      startIndex + itemsPerPage
                                    )
                                    .map((image, index) => (
                                      <img
                                        src={image?.public_url}
                                        alt=""
                                        key={index}
                                        className={`h-[47px] cursor-pointer rounded-md ${
                                          image?.public_url ===
                                          showImageForMoment
                                            ? "slider-img-active"
                                            : "slider-img"
                                        }`}
                                        onClick={() =>
                                          handleMomentImageClick(image)
                                        }
                                      />
                                    ))}
                              </div>
                              <div>
                                <div className="text-center relative">
                                  <div onClick={() => next("momentImage")}>
                                    <SliderRightSideSVG
                                      startIndex={startIndex}
                                      itemsPerPage={itemsPerPage}
                                      totalPages={totalPages}
                                    />
                                  </div>
                                  <span className="text-xs absolute inline-block whitespace-nowrap left-[20%] translate-x-[-50%] bottom-[-30px]">{`${
                                    Math.floor(startIndex) + 1
                                  }-${
                                    itemsPerPage + startIndex >
                                    imagesForMoment?.length
                                      ? imagesForMoment?.length
                                      : itemsPerPage + startIndex
                                  } of ${imagesForMoment?.length}`}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                        <div className="py-3 pr-3 pl-3 lg:pl-8 lg:ml-12 lg:border-l-[1px] borer-[#ECE5E5]">
                          <h5 className="text-[20px] text-[#060606] opacity-70 font-normal">
                            Moment Statistics
                          </h5>
                          <ul className="flex pt-2">
                            <li
                              className={`font-normal text=[#060606] text-[12px] sm:text-[16px] cursor-pointer border-b-[1.53px] pb-1 ${
                                currentMomentChart === "gender"
                                  ? "border-[#674CB4]"
                                  : "opacity-60"
                              }`}
                              onClick={() => setCurrentMomentChart("gender")}
                            >
                              Gender
                            </li>
                            <li
                              className={`font-normal text=[#060606] text-[12px] sm:text-[16px] cursor-pointer  ml-4 sm:ml-7 border-b-[1.53px] pb-1 ${
                                currentMomentChart === "groupSize"
                                  ? "border-[#674CB4]"
                                  : "opacity-60"
                              }`}
                              onClick={() => setCurrentMomentChart("groupSize")}
                            >
                              Group size
                            </li>
                            <li
                              className={`font-normal text=[#060606] text-[12px] sm:text-[16px] cursor-pointer  ml-4 sm:ml-7 border-b-[1.53px] pb-1 ${
                                currentMomentChart === "time"
                                  ? "border-[#674CB4]"
                                  : "opacity-60"
                              }`}
                              onClick={() => setCurrentMomentChart("time")}
                            >
                              Time of day
                            </li>
                            <li
                              className={`font-normal text=[#060606] text-[12px] sm:text-[16px] cursor-pointer  ml-4 sm:ml-7 border-b-[1.53px] pb-1 ${
                                currentMomentChart === "location"
                                  ? "border-[#674CB4]"
                                  : "opacity-60"
                              }`}
                              onClick={() => setCurrentMomentChart("location")}
                            >
                              Location
                            </li>
                          </ul>
                          <div className="relative flex justify-center items-center h-[365px]">
                            {momentData && momentData?.length > 0 && (
                              <>
                                {currentMomentChart === "gender" && (
                                  <Donut
                                    momentData={momentData}
                                    momentId={momentId}
                                  />
                                )}
                                {currentMomentChart === "groupSize" && (
                                  <GroupSizeApexChart
                                    momentData={momentData}
                                    momentId={momentId}
                                  />
                                )}
                                {currentMomentChart === "time" && (
                                  <TimeOfDayApexChart
                                    momentData={momentData}
                                    momentId={momentId}
                                  />
                                )}
                                {currentMomentChart === "location" && (
                                  <LocationApexChart
                                    momentData={momentData}
                                    momentId={momentId}
                                  />
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <Emotions_Motivations
                        featureData={featureData}
                        loading={loading}
                      />
                      <Wh51 featureData={featureData} loading={loading} />
                      <EmergingThemes
                        featureData={featureData}
                        loading={loading}
                        handleEmergingThemes={handleEmergingThemes}
                        emergingThemes={emergingThemes}
                        firstRender={firstRender}
                        keyThemesTitle={keyThemesTitle}
                      />
                      <BrandRelevance_Marketing
                        featureData={featureData}
                        loading={loading}
                        brandRelevance={brandRelevance}
                        handleBrandRelevance={handleBrandRelevance}
                        marketingOpportunities={marketingOpportunities}
                        handleMarketingOpportunities={
                          handleMarketingOpportunities
                        }
                      />
                    </>
                  )}
                  {showImageData && (
                    <>
                      <h5 className="text-[#060606] text-[18px] font-medium py-4 px-3 sticky top-0 bg-white z-10">
                        {featureDataForImage?.moment?.title}
                      </h5>
                      <Emotions_Motivations
                        featureData={featureDataForImage}
                        loading={loadingForImage}
                      />
                      <Wh51
                        featureData={featureDataForImage}
                        loading={loadingForImage}
                      />
                      {
                        <EmergingThemes
                          featureData={featureDataForImage}
                          loading={loadingForImage}
                          handleEmergingThemes={handleEmergingThemes}
                          emergingThemes={emergingThemes}
                          firstRender={firstRender}
                          keyThemesTitle={keyThemesTitle}
                          imageId={imageId}
                          showImageForMoment={showImageForMoment}
                          imageFirstRender={imageFirstRender}
                        />
                      }
                      <BrandRelevance_Marketing
                        featureData={featureDataForImage}
                        loading={loadingForImage}
                        brandRelevance={brandRelevance}
                        handleBrandRelevance={handleBrandRelevance}
                        marketingOpportunities={marketingOpportunities}
                        handleMarketingOpportunities={
                          handleMarketingOpportunities
                        }
                      />
                      <>
                        <h4 className="p-3 border border-[#ECE5E5] text-[20px] font-medium text-[#060606]  bg-[#7130a51a]">
                          Have any further questions?
                        </h4>
                        <div className="p-2 rounded bg-[#7979790D]">
                          {promptError && (
                            <p className="ml-4 text-[12px] text-red-500">
                              {promptError}
                            </p>
                          )}
                          <div className="grid grid-cols-12 gap-2">
                            <div className="max-sm:col-span-12 sm:col-span-12 col-span-10 rounded bg-white">
                              <div>
                                <form
                                  className="w-full h-full flex items-center"
                                  onSubmit={handlePromptSubmitForImage}
                                >
                                  <input
                                    type="text"
                                    className="px-3 py-2 w-full h-full focus:outline-none"
                                    placeholder="Ask a question about the image"
                                    value={promptInputValue}
                                    onChange={handlePromptInputChange}
                                  />
                                  <button
                                    className="my-2 mr-3 pl-2 pr-3 py-1 flex items-center gap-1 rounded-lg bg-[#070B32] text-white"
                                    onClick={handlePromptSubmitForImage}
                                  >
                                    <PepSVG /> Ask
                                  </button>
                                </form>
                              </div>
                            </div>
                            <div className="max-sm:col-span-12 p-4 sm:col-span-12 col-span-12 rounded bg-white relative">
                              {propmtResponseLoading && (
                                <div className="content-spinner">
                                  <ContainerSpinner />
                                </div>
                              )}
                              <textarea
                                rows="6"
                                className="w-full outline-none resize-none scroll-element"
                                value={promptResponse}
                                readOnly
                                ref={textareaRef}
                              ></textarea>
                              {promptResponse && (
                                <div className="flex justify-end ">
                                  <div
                                    className="cursor-pointer"
                                    onClick={handleCopyClick}
                                  >
                                    <TextCopySVG />
                                  </div>
                                  <div className="mx-2 cursor-pointer">
                                    <LikedSVG />
                                  </div>
                                  <div className="cursor-pointer">
                                    <DislikedSVG />
                                  </div>
                                  <div
                                    className="mx-2 cursor-pointer"
                                    onClick={handlePromptSubmitForImage}
                                  >
                                    <RegenerateSVG />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    </>
                  )}
                </>
              </>
            )}
          </div>

          {(initialRender || isMomentData) && (
            <>
              <h4 className="p-3 border border-[#ECE5E5] text-[20px] font-medium text-[#060606]  bg-[#7130a51a] flex mb-0 items-center">
                Ask me anything about...
                <select
                  className="px-3 ml-5 rounded-lg ask-me text-[14px] h-[44px] w-[279px] bg-white py-2 font-[400] text-[#060606] focus:outline-none cursor-pointer"
                  value={promptSelectedOption}
                  onChange={handleSelectOptionChange}
                >
                  <option value="" disabled="true">
                    Select an option
                  </option>
                  {promptSelectOptions.map((option) => (
                    <option
                      key={option.title}
                      value={option.title}
                      disabled={option.disabled}
                    >
                      {option.value}
                    </option>
                  ))}
                </select>
              </h4>
              <div className="p-2 rounded bg-[#7979790D]">
                <div className="grid grid-cols-12 gap-2">
                  <div className="col-span-12 rounded bg-white">
                    <div>
                      {promptError && (
                        <p className="ml-4 text-[12px] text-red-500">
                          {promptError}
                        </p>
                      )}
                      <form
                        className="w-full h-full flex items-center"
                        onSubmit={handlePromptSubmit}
                      >
                        <input
                          type="text"
                          className="px-3 py-2 w-full h-full focus:outline-none"
                          placeholder="Ask a question about the image"
                          value={promptInputValue}
                          onChange={handlePromptInputChange}
                        />
                        <button
                          className="my-2 mr-3 pl-2 pr-3 py-1 flex items-center gap-1 rounded-lg bg-[#070B32] text-white"
                          onClick={handlePromptSubmit}
                        >
                          <PepSVG /> Ask
                        </button>
                      </form>
                    </div>
                  </div>
                  <div className="max-sm:col-span-12 p-4 sm:col-span-12 col-span-12 rounded bg-white relative">
                    {propmtResponseLoading && (
                      <div className="content-spinner">
                        <ContainerSpinner />
                      </div>
                    )}
                    <textarea
                      rows="6"
                      className="w-full outline-none resize-none scroll-element"
                      value={promptResponse}
                      readOnly
                      ref={textareaRef}
                    ></textarea>
                    {promptResponse && (
                      <div className="flex justify-end ">
                        <div
                          className="cursor-pointer"
                          onClick={handleCopyClick}
                        >
                          <TextCopySVG />
                        </div>
                        <div className="mx-2 cursor-pointer">
                          <LikedSVG />
                        </div>
                        <div className="cursor-pointer">
                          <DislikedSVG />
                        </div>
                        <div
                          className="mx-2 cursor-pointer"
                          onClick={handlePromptSubmit}
                        >
                          <RegenerateSVG />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {isStatusResponseModalVisible && (
        <StatusResponse_Modal
          StatusResponseModalData={StatusResponseModalData}
        />
      )}
    </>
  );
}
