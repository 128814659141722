import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

// Contexts
import { useAuth } from "./contexts/AuthContext";

// Pages
import LoginPage from "./pages/LoginPage";
// import NewUIPage from "./pages/NewUIPage";

// Components
import Protected from "./component/Protected";
import LensInsightPage from "./pages/LensInsightPage";
import FilterContextProvider from "./pages/context/FilterContext";
import UploadImage from "./pages/uploadImage";
/* import ImageSetPage from "./component/ImageSetPage";
import ImageSetPageOld from "./component/LensInsights"; */

function App() {
  const { userLoading } = useAuth();

  if (userLoading) {
    return (
      <div>
        <span className="visually-hidden">Loading...</span>
      </div>
    );
  }

  return (
    <>
      <ToastContainer />
      <FilterContextProvider>
        <Routes>
          <Route path="/login" element={<LoginPage />} />

          {/* Protected Route */}
          <Route path="/" element={<Protected />}>
            <Route index element={<LensInsightPage />} />
            <Route path="/upload_image"  element={<UploadImage />} />
            {/*  <Route path="/insights" element={<ImageSetPageOld />} />
          <Route path="/image-set" element={<ImageSetPage />} /> */}
          </Route>
        </Routes>
      </FilterContextProvider>
    </>
  );
}

export default App;
