import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDL8M80D2us8XSv6YOnffi8IoiihiMEKXY",
  authDomain: "lens-404712.firebaseapp.com",
  projectId: "lens-404712",
  storageBucket: "lens-404712.appspot.com",
  messagingSenderId: "588881627657",
  appId: "1:588881627657:web:1abe2e8122c177bb15c6d8",
  measurementId: "G-Y76ZJNPKJD",
};

const app = initializeApp(firebaseConfig);

getAnalytics(app);

export const auth = getAuth(app);
