import { useContext, useEffect, useState } from "react";
import { arrowUpSvg, arrowDownSvg } from "../../assets/images";
import { sidebarData } from "../ImageSet/constant";
import { FilterContext } from "../context/FilterContext";

const Filters = ({
  applyFilter,
  allFilters,
  outdoorFilters,
  resetFilter,
  setImageFirstRender,
  isOpenFilter,
  setIsOpenFilter,
  SetShowMomentData,
}) => {
  // const [mySidebarData, setMySidebarData] = useState(sidebarData);
  const {
    sideBarData,
    setSideBarData,
    handlesideBarData,
    displaySideBarData,
    setDisplaySideBarData,
    handleDisplaySideBarData,
  } = useContext(FilterContext);

  useEffect(() => {
    if (allFilters) {
      const updatedSideBarData = sideBarData.map((item) => {
        if (item.key === "location") {
          // For the first object inside "Location"
          if (item.children && item.children.length > 0) {
            const firstLocationChild = item.children[0];
            if (firstLocationChild.key === "indoor") {
              // Fill children with values from allFilters.indoor
              const indoorChildren = Object.keys(allFilters.indoor).map(
                (r, ri) => ({
                  id: ri + 1,
                  value: r,
                  name: r.charAt(0).toUpperCase() + r.slice(1),
                  isChecked: false,
                })
              );
              return {
                ...item,
                children: [
                  {
                    ...firstLocationChild,
                    children: indoorChildren,
                  },
                  // Keep the second object as it is
                  item.children[1],
                ],
              };
            }
          }
        }

        // For other keys, update children as usual
        const curFiltObj = allFilters[item.key];
        const childrens = Object.keys(curFiltObj).map((r, ri) => ({
          id: ri + 1,
          value: r,
          name: r.charAt(0).toUpperCase() + r.slice(1),
          isChecked: false,
        }));

        return {
          ...item,
          children: childrens,
        };
      });

      handlesideBarData(updatedSideBarData);
    }
  }, [allFilters]);

   useEffect(() => {
    setSideBarData((pre) =>
      pre.map((p) =>
        p.key === "location"
          ? {
              ...p,
              children: p.children.map((pc) =>
                pc.key === "outdoor"
                  ? {
                      ...pc,
                      children: outdoorFilters.map((val, i) => ({
                        id: i,
                        name: val,
                        value: val,
                        isChecked: false,
                      })),
                    }
                  : pc
              ),
            }
          : p
      )
    );
  }, [outdoorFilters]);

  const toggleFilter = (filterId) => {
    setSideBarData((pre) => {
      return pre.map((p) => {
        p.isActive = p.id === filterId;
        return p;
      });
    });
  };

  const clearFilter = () => {
    setSideBarData((pre) =>
      pre.map((p) => ({
        ...p,
        children: p.children.map((pc) => ({ ...pc, isChecked: false })),
      }))
    );
    handleDisplaySideBarData({});
    setIsOpenFilter(false);
    resetFilter();
  };

  const handleCheckboxChange = (sid, cid) => {
    setSideBarData((pre) =>
      pre.map((p) =>
        p.id === sid
          ? {
              ...p,
              children: p.children.map((pc) =>
                pc.id === cid
                  ? {
                      ...pc,
                      isChecked:
                        allFilters?.[p.key]?.[pc.value] > 0 && !pc.isChecked,
                    }
                  : pc
              ),
            }
          : p
      )
    );
  };

  const handleSubCheckboxChange = (sid, cid, ccid) => {
    setSideBarData((pre) =>
      pre.map((p) =>
        p.id === sid
          ? {
              ...p,
              children: p.children.map((pc) =>
                pc.id === cid && pc.children
                  ? {
                      ...pc,
                      children: pc.children.map((pcc) =>
                        pcc.id === ccid
                          ? {
                              ...pcc,
                              isChecked:
                                allFilters?.[pc.key]?.[pcc.value] > 0 &&
                                !pcc.isChecked,
                            }
                          : pcc
                      ),
                    }
                  : pc
              ),
            }
          : p
      )
    );
  };

  const onApplyFilter = async () => {
    setImageFirstRender(true);
    SetShowMomentData(false);
    const reqObj = {};
    const filteredArr = sideBarData.map((sData) => {
      return sData.children.map((cData) => {
        if (cData.isChecked) {
          if (reqObj[sData.key] && reqObj[sData.key].length) {
            reqObj[sData.key].push(cData.value);
          } else {
            reqObj[sData.key] = [cData.value];
          }
        }
        if (cData.children) {
          cData.children.map((ccData) => {
            if (ccData.isChecked) {
              if (reqObj[cData.key] && reqObj[cData.key].length) {
                reqObj[cData.key].push(ccData.value);
              } else {
                reqObj[cData.key] = [ccData.value];
              }
            }
            return ccData;
          });
        }
        return cData;
      });
    });
    handleDisplaySideBarData(reqObj);
    setIsOpenFilter(false);
    applyFilter(reqObj);
    return filteredArr;
  };

  return (
    <>
      {isOpenFilter && (
        <aside
          id="sidebar-multi-level-sidebar"
          className="left-0 bg-white top-[100%] absolute z-10 w-[300px]"
          aria-label="Sidebar"
        >
          <div className="h-full p-3 overflow-auto shadow-md">
            <ul className="list-unstyled mb-2 font-medium">
              {sideBarData.map((sData) => (
                <li key={sData.id} onClick={() => toggleFilter(sData.id)}>
                  <button
                    type="button"
                    className={`btn flex items-center justify-between  w-100 p-2 text-base mb-2.5 ${
                      sData.isActive ? "active-text" : "text-white"
                    } rounded-lg ${sData.isActive ? "" : ""}`}
                  >
                    <div className="flex">
                      {sData.isActive ? sData.activeIcon : sData.inactiveIcon}
                      <span
                        className={`flex-1 ms-3 text-left rtl-text-right`}
                        style={
                          sData.isActive
                            ? { color: "#674CB4" }
                            : { color: "#060606" }
                        }
                      >
                        {sData.name}
                      </span>
                    </div>
                    <img
                      className="w-3 h-3"
                      src={sData.isActive ? arrowUpSvg : arrowDownSvg}
                      alt=""
                    />
                  </button>
                  {sData.isActive && (
                    <ul id="dropdown-example" className="py-2 space-y-2 ">
                      {sData.children.map((cData) => (
                        <li key={cData.id} className="filter-checkbox">
                          {cData.children ? (
                            <>
                              <p className="mb-2" style={{ color: "#674CB4" }}>
                                {cData.name} (
                                {allFilters?.[sData?.key]?.[cData?.key] || 0})
                              </p>
                              <ul className="ml-3 list-unstyled mb-2 font-medium  max-h-[100px] overflow-auto scroll-element">
                                {cData.children.map((ccData) => (
                                  <li className="ml-3 mb-2" key={ccData.id}>
                                    <input
                                      id={ccData.name}
                                      type="checkbox"
                                      className="form-check-input border-[#674CB4]"
                                      checked={ccData.isChecked}
                                      onChange={() =>
                                        handleSubCheckboxChange(
                                          sData.id,
                                          cData.id,
                                          ccData.id
                                        )
                                      }
                                      // disabled={true}
                                    />
                                    <label
                                      htmlFor={ccData.name}
                                      className="form-check-label text-[16px] font-italic ms-[8px] cursor-pointer"
                                      style={
                                        sData.isActive
                                          ? { color: "#674CB4" }
                                          : { color: "#060606" }
                                      }
                                    >
                                      {ccData.name} (
                                      {allFilters?.[cData?.key]?.[
                                        ccData?.value
                                      ] || 0}
                                      )
                                    </label>
                                  </li>
                                ))}
                              </ul>
                            </>
                          ) : (
                            <div className="ml-3 form-check mb-2">
                              <input
                                id={cData.name}
                                type="checkbox"
                                className={`form-check-input ${
                                  allFilters?.[sData?.key]?.[cData?.value] > 0
                                    ? "border-[#674CB4]"
                                    : " border-[#808080] zero-value"
                                }`}
                                checked={cData.isChecked}
                                onChange={() =>
                                  handleCheckboxChange(sData.id, cData.id)
                                }
                              />
                              <label
                                htmlFor={cData.name}
                                className={`form-check-label text-[16px] font-italic ms-[8px] cursor-pointer ${
                                  sData.isActive
                                    ? "text-[#674CB4]"
                                    : "text-[#060606]"
                                } ${
                                  allFilters?.[sData?.key]?.[cData?.value] > 0
                                    ? ""
                                    : "zero-value"
                                }`}
                                style={
                                  sData.isActive
                                    ? { color: "#674CB4" }
                                    : { color: "#060606" }
                                }
                              >
                                {cData.name} (
                                {allFilters?.[sData?.key]?.[cData?.value] || 0})
                              </label>
                            </div>
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>

            <div className="w-100 d-flex justify-content-between mt-3 gap-1">
              <button
                onClick={clearFilter}
                className="sidebar-button group border-purple-600 text-black rounded-3 border-3 px-4 transition duration-300 ease-in-out hover:bg-[#674CB4] hover:text-white"
              >
                Reset
              </button>
              <button
                onClick={() => onApplyFilter()}
                className="sidebar-button bg-[#674CB4] py-2 	border px-8 border-white text-white rounded-3"
              >
                Apply
              </button>
            </div>
          </div>
        </aside>
      )}
    </>
  );
};

export default Filters;
