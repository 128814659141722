import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  GoogleLoginButton,
  MicrosoftLoginButton,
} from "react-social-login-buttons";

import { useAuth } from "../contexts/AuthContext";

const LoginPage = () => {
  const navigate = useNavigate();
  const { googleSignIn, microsoftSignIn, user } = useAuth();

  const handleGoogleSignIn = async () => {
    await googleSignIn();
  };

  const handleMicrosoftSignIn = async () => {
    await microsoftSignIn();
  };

  useEffect(() => {
    if (user) {
      navigate("/", { replace: true });
    }
  }, [user]);

  return (
    <div className="w-full h-screen flex justify-center items-center">
      <div className="p-6 flex flex-col items-center gap-y-5 border rounded-lg">
        <h1 className="text-2xl">Login with your account</h1>
        <GoogleLoginButton onClick={handleGoogleSignIn} />
        <MicrosoftLoginButton onClick={handleMicrosoftSignIn} />
      </div>
    </div>
  );
};

export default LoginPage;
