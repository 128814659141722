import React from "react";
import ContainerSpinner from "./ContainerSpinner";
import userIcon from "../../assets/images/png/user-icon.png";
import whatIcon from "../../assets/images/png/what-icon.png";
import whereIcon from "../../assets/images/png/where-icon.png";
import whenIcon from "../../assets/images/png/when-icon.png";
import whyIcon from "../../assets/images/png/why-icon.png";
import howIcon from "../../assets/images/png/how-icon.png";
function Wh51({ featureData, loading }) {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 px-5 mb-4 relative">
      {loading?.w5h1 && (
        <div className="content-spinner">
          <ContainerSpinner />
        </div>
      )}
      <div className="border-[0.89px] border-[#f0c274cc] rounded-[12px] bg-white p-3">
        <div className="content">
          <div className="flex items-center">
            <div className="h-[41px] flex items-center justify-center bg-[#F0C274] rounded-full w-[41px]">
              <img src={userIcon} />
            </div>
            <h6 className="text-[16px] font-normal text-[#060606] opacity-70 ml-3">
              Who
            </h6>
          </div>
          <p className="text-[14px] lg:text=[15px] 2xl:text-[16px] font-light text-[#060606] mt-2 mb-0">
            {featureData && featureData?.w5h1?.who}
          </p>
        </div>
      </div>
      <div className="border-[0.89px] border-[#d398e7cc] rounded-[12px] bg-white p-3">
        <div className="content">
          <div className="flex items-center">
            <div className="h-[41px] flex items-center justify-center bg-[#D398E7] rounded-full w-[41px]">
              <img src={whatIcon} />
            </div>
            <h6 className="text-[16px] font-normal text-[#060606] opacity-70 ml-3">
              What
            </h6>
          </div>
          <p className="text-[14px] lg:text=[15px] 2xl:text-[16px] font-light text-[#060606] mt-2 mb-0">
            {featureData && featureData?.w5h1?.what}
          </p>
        </div>
      </div>
      <div className="border-[0.89px] border-[#70a1e5b3] rounded-[12px] bg-white p-3">
        <div className="content">
          <div className="flex items-center">
            <div className="h-[41px] flex items-center justify-center bg-[#70A1E5] rounded-full w-[41px]">
              <img src={whereIcon} />
            </div>
            <h6 className="text-[16px] font-normal text-[#060606] opacity-70 ml-3">
              Where
            </h6>
          </div>
          <p className="text-[14px] lg:text=[15px] 2xl:text-[16px] font-light text-[#060606] mt-2 mb-0">
            {featureData && featureData?.w5h1?.where}
          </p>
        </div>
      </div>
      <div className="border-[0.89px] border-[#c5da70cc] rounded-[12px] bg-white p-3">
        <div className="content">
          <div className="flex items-center">
            <div className="h-[41px] flex items-center justify-center bg-[#C5DA70] rounded-full w-[41px]">
              <img src={whenIcon} />
            </div>
            <h6 className="text-[16px] font-normal text-[#060606] opacity-70 ml-3">
              When
            </h6>
          </div>
          <p className="text-[14px] lg:text=[15px] 2xl:text-[16px] font-light text-[#060606] mt-2 mb-0">
            {featureData && featureData?.w5h1?.when}
          </p>
        </div>
      </div>
      <div className="border-[0.89px] border-[#df4e1080] rounded-[12px] bg-white p-3">
        <div className="content">
          <div className="flex items-center">
            <div className="h-[41px] flex items-center justify-center bg-[#df4e10cc] rounded-full w-[41px]">
              <img src={whyIcon} />
            </div>
            <h6 className="text-[16px] font-normal text-[#060606] opacity-70 ml-3">
              Why
            </h6>
          </div>
          <p className="text-[14px] lg:text=[15px] 2xl:text-[16px] font-light text-[#060606] mt-2 mb-0">
            {featureData && featureData?.w5h1?.why}
          </p>
        </div>
      </div>
      <div className="border-[0.89px] border-[#d6629ab3] rounded-[12px] bg-white p-3">
        <div className="content">
          <div className="flex items-center">
            <div className="h-[41px] flex items-center justify-center bg-[#D6629A] rounded-full w-[41px]">
              <img src={howIcon} />
            </div>
            <h6 className="text-[16px] font-normal text-[#060606] opacity-70 ml-3">
              How
            </h6>
          </div>
          <p className="text-[14px] lg:text=[15px] 2xl:text-[16px] font-light text-[#060606] mt-2 mb-0">
            {featureData && featureData?.w5h1?.how}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Wh51;
