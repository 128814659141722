import { createContext, useState } from "react";
import { sidebarData } from "../ImageSet/constant";

export const FilterContext = createContext("");

function FilterContextProvider({ children }) {
  const [sideBarData, setSideBarData] = useState(sidebarData);
  const [displaySideBarData, setDisplaySideBarData] = useState({});

  const handlesideBarData = (data) => {
    setSideBarData(data);
  };
  const handleDisplaySideBarData = (data) => {
    setDisplaySideBarData(data);
  };

  return (
    <div>
      <FilterContext.Provider
        value={{
          sideBarData,
          setSideBarData,
          handlesideBarData,
          displaySideBarData,
          setDisplaySideBarData,
          handleDisplaySideBarData,
        }}
      >
        {children}
      </FilterContext.Provider>
    </div>
  );
}

export default FilterContextProvider;
