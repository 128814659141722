import { useContext, createContext, useEffect, useState } from "react";
import {
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
  onIdTokenChanged,
  OAuthProvider,
} from "firebase/auth";
import { toast } from "react-toastify";

import { auth } from "../config/firebase";
import {
  removeAccessTokenToStorage,
  setAccessTokenToStorage,
} from "../utils/storage";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userLoading, setUserLoading] = useState(true);

  const handleAuthError = (error) => {
    let errorMessage = "Something went wrong while login";

    const stringWithJson = error?.message;
    const startIndex = stringWithJson.indexOf("{");
    const endIndex = stringWithJson.lastIndexOf("}");

    if (startIndex === -1 || endIndex === -1 || endIndex <= startIndex) {
      errorMessage = "Something went wrong while login";
    } else {
      const jsonString = stringWithJson.substring(startIndex, endIndex + 1);

      try {
        const jsonObject = JSON.parse(jsonString);
        errorMessage =
          jsonObject?.error?.message || "Something went wrong while login";
      } catch (error) {
        errorMessage = "Something went wrong while login";
      }
    }

    toast.error(errorMessage, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
    });
  };

  const googleSignIn = async () => {
    const provider = new GoogleAuthProvider();

    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      handleAuthError(error);
    }
  };

  const microsoftSignIn = async () => {
    const provider = new OAuthProvider("microsoft.com");

    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      handleAuthError(error);
    }
  };

  const logOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setUserLoading(true);
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUserLoading(false);
      setUser(currentUser);

      if (currentUser) {
        setAccessTokenToStorage(currentUser?.accessToken);
      } else {
        removeAccessTokenToStorage();
      }
    });

    const unsubscribeToken = onIdTokenChanged(auth, (currentUser) => {
      if (currentUser) {
        setAccessTokenToStorage(currentUser?.accessToken);
      } else {
        removeAccessTokenToStorage();
      }
    });

    return () => {
      unsubscribe();
      unsubscribeToken();
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{ googleSignIn, microsoftSignIn, logOut, user, userLoading }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
