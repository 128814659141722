export const setAccessTokenToStorage = (value) => {
  localStorage.setItem('accessToken', value);
};

export const removeAccessTokenToStorage = () => {
  localStorage.removeItem('accessToken');
};

export const getAccessTokenFromStorage = () => {
  return localStorage.getItem('accessToken');
};
